import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem, treeItemClasses } from "@mui/x-tree-view/TreeItem";
import { useTreeItem } from "@mui/x-tree-view/TreeItem";
import Typography from "@mui/material/Typography";
import clsx from "clsx";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CropSquareIcon from "@mui/icons-material/CropSquare";
import SelectAllIcon from "@mui/icons-material/SelectAll";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import {
  getPages,
  getQuestionGroups,
  getSurveyLookupFiling,
} from "../../pages/Survey/Survey.service";
import {
  fetchQuestionsInPage,
  fetchSurveyFiling,
  fetchPageFilingsBySurveyFilingId,
  setDataIds,
  setQuestionsData,
  setPagePayload,
  setShowLanding,
  setShowErrorPage,
} from "../../pages/Survey/Survey.slice";
import { getTextFromHTML, htmlParser } from "../../Utility/constants";

function truncateText(text, limit) {
  if (text.length > limit) {
    return text.slice(0, limit) + " ...";
  } else {
    return text;
  }
}

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

function CircularWithValueLabel({ value }) {
  return <CircularProgressWithLabel value={value} />;
}

const RootTreeView = styled(TreeView)(({ theme }) => ({
  borderRadius: "14px",
  backgroundColor: "rgba(0, 48, 135, 0.15)",
  [`& .${treeItemClasses.group}`]: {
    paddingLeft: 18,
  },
}));

function SectionTreeItem(props) {
  return <TreeItem ContentComponent={SectionContent} {...props} />;
}

const SectionContent = React.forwardRef(function SectionContent(props, ref) {
  const {
    className,
    classes,
    label,
    nodeId,
    icon: iconProp,
    expansionIcon,
    displayIcon,
  } = props;

  const [progress, setProgress] = useState(0);
  let sectionFilings = useSelector((state) => state.survey.sectionFilings);
  const supSectionFiling = useSelector(
    (state) => state?.survey?.supSectionFiling
  );

  if (supSectionFiling && supSectionFiling.length > 0) {
    sectionFilings = [...sectionFilings, ...supSectionFiling];
  }

  useEffect(() => {
    if (sectionFilings?.length > 0) {
      const filteredData = sectionFilings.filter(
        (item) => item.sectionId == nodeId
      );
      const ProgressPercentage = filteredData?.[0]?.percentPagesCompleted;
      setProgress(ProgressPercentage ? ProgressPercentage : 0);
    }
  }, [sectionFilings]);

  const {
    disabled,
    expanded,
    selected,
    focused,
    handleExpansion,
    handleSelection,
    preventSelection,
  } = useTreeItem(nodeId);

  const icon = iconProp || expansionIcon || displayIcon;
  const selecticon = <CropSquareIcon />;

  const handleMouseDown = (event) => {
    preventSelection(event);
  };

  const handleClick = (event) => {
    handleExpansion(event);
    handleSelection(event);
  };

  return (
    <div
      style={{
        backgroundColor: "rgba(0, 48, 135, 0.15)",
        padding: "10px",
        border: "1px solid rgba(0, 48, 135, 1)",
        borderRadius: "14px",
        ...(selected && { backgroundColor: "rgba(0, 48, 135, 0.15)" }),
        ...(expanded && { backgroundColor: "rgba(0, 48, 135, 0.15)" }),
        ...(focused && { backgroundColor: "rgba(0, 48, 135, 0.35)" }),
      }}
      className={clsx(className, classes.label, {
        "Mui-expanded": expanded,
        "Mui-selected": selected,
        "Mui-focused": focused,
        "Mui-disabled": disabled,
      })}
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      ref={ref}
    >
      <Typography component="div" className={classes.label}>
        {label}
      </Typography>
      <div className={classes.iconContainer}>{icon}</div>
      <CircularWithValueLabel value={progress} />
    </div>
  );
});

function PageTreeItem(props) {
  return <TreeItem ContentComponent={PageContent} {...props} />;
}

const PageContent = React.forwardRef(function PageContent(props, ref) {
  const {
    className,
    classes,
    label,
    nodeId,
    icon: iconProp,
    expansionIcon,
    displayIcon,
    onClick,
  } = props;

  const {
    disabled,
    expanded,
    selected,
    focused,
    handleExpansion,
    handleSelection,
    preventSelection,
  } = useTreeItem(nodeId);

  const icon = iconProp || expansionIcon || displayIcon;

  const handleMouseDown = (event) => {
    preventSelection(event);
  };

  const handleClick = (event) => {
    handleExpansion(event);
    handleSelection(event);
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <div
      style={{
        backgroundColor: "rgba(0, 48, 135, 0.15)",
        padding: "10px",
        borderRadius: "14px",
        ...(selected && { backgroundColor: "rgba(0, 48, 135, 0.15)" }),
        ...(expanded && {
          backgroundColor: "rgba(0, 48, 135, 0.15)",
          borderBottom: "1px solid rgba(0, 48, 135, 1)",
        }),
        ...(focused && { backgroundColor: "rgba(0, 48, 135, 0.35)" }),
      }}
      className={clsx(className, classes.label, {
        "Mui-expanded": expanded,
        "Mui-selected": selected,
        "Mui-focused": focused,
        "Mui-disabled": disabled,
      })}
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      ref={ref}
    >
      <Typography component="div" className={classes.label}>
        {label}
      </Typography>
      <div className={classes.iconContainer}>{icon}</div>
    </div>
  );
});

function QuestionTreeItem(props) {
  return <TreeItem ContentComponent={QuestionContent} {...props} />;
}

const QuestionContent = React.forwardRef(function QuestionContent(props, ref) {
  const {
    className,
    classes,
    label,
    nodeId,
    icon: iconProp,
    expansionIcon,
    displayIcon,
    onClick,
  } = props;

  const {
    disabled,
    expanded,
    selected,
    focused,
    handleExpansion,
    handleSelection,
    preventSelection,
  } = useTreeItem(nodeId);

  const icon = iconProp || expansionIcon || displayIcon;
  const selecticon = <CropSquareIcon />;

  const handleMouseDown = (event) => {
    preventSelection(event);
  };

  const handleClick = (event) => {
    handleExpansion(event);
    handleSelection(event);
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <div
      style={{
        backgroundColor: "transparent",
        padding: "8px 0",
        ...(selected && {
          color: "#1b1ba7",
          backgroundColor: "#9dafd1",
          borderRadius: "7px",
        }),
      }}
      className={clsx(className, classes.label, {
        "Mui-expanded": expanded,
        "Mui-selected": selected,
        "Mui-focused": focused,
        "Mui-disabled": disabled,
      })}
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      ref={ref}
    >
      <Typography variant="label" className={classes.label}>
        {label}
      </Typography>
    </div>
  );
});

function PageTreeItems({ section }) {
  let allPages = useSelector((state) => state.survey.allPages);
  const supPages = useSelector((state) => state?.survey?.supPages);

  if (supPages && supPages.length > 0) {
    allPages = [...allPages, ...supPages];
  }
  const pages = allPages.filter((obj) => obj.section.id === section.id);
  const selectedPageId = useSelector(
    (state) => state.survey.currentSelection.pageId
  );
  const dispatch = useDispatch();
  const pageRef = useRef();

  const [selectedPages, setSelectedPages] = useState([]);
  const pageFilings = useSelector((state) => state.survey.pageFilings);
  const supPageFilings = useSelector((state) => state?.survey?.supPageFilings);

  useEffect(() => {
    let mergedPageFilings = pageFilings;
    if (supPageFilings && supPageFilings.length > 0) {
      mergedPageFilings = [...pageFilings, ...supPageFilings];
    }

    const skippedPages = mergedPageFilings.reduce((acc, obj) => {
      if (obj.status && obj.status.name === "Page Skipped") {
        acc.push(obj.pageId);
      }
      return acc;
    }, []);

    const updatedSelectedPages = pages.filter((page, index) => {
      return !skippedPages.includes(page.id);
    });

    setSelectedPages(updatedSelectedPages);
  }, [pageFilings, supPageFilings]);

  useEffect(() => {
    if (pageRef.current) {
      pageRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  }, [pageRef, selectedPageId]);

  return (
    <>
      {selectedPages.map((page, index) => (
        <PageTreeItem
          onClick={() => {
            dispatch(setDataIds({ secId: section.id, pgId: page.id }));
            dispatch(setShowLanding(false));
          }}
          key={page.id}
          selecticon={<CropSquareIcon />}
          nodeId={page.id.toString()}
          label={`Page-${index + 1} : ${truncateText(
            getTextFromHTML(page.name),
            20
          )}`}
          sx={{
            backgroundColor: "rgba(0, 48, 135, 0.15)",
            border: "1px solid rgba(0, 48, 135, 1)",
            borderRadius: "14px",
            [`& .${treeItemClasses.group}`]: {
              maxHeight: 200,
              overflowY: "auto",
              overflowX: "hidden",
              margin: "12px 12px 12px -5px",
              scrollbarWidth: "thin",
              scrollbarColor: "rgba(0, 48, 135, 1) white",
              "&::-webkit-scrollbar": {
                width: 3,
                backgroundColor: "white",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "rgba(0, 48, 135, 1)",
                borderRadius: 4,
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "transparent",
              },
            },
            margin: "0px 10px 10px -18px",
          }}
        >
          <div ref={pageRef}>
            <QuestionTreeItems page={page} index={index} />
          </div>
        </PageTreeItem>
      ))}
    </>
  );
}

function QuestionTreeItems({ page }) {
  const [questions, setQuestions] = React.useState([]);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const surveyId = useSelector((state) => state.survey.activeSurveys[0]?.id);
  const surveyFilingId = useSelector(
    (state) => state.survey.surveyFiling[0]?.id
  );

  let pageFilings = useSelector((state) => state.survey.pageFilings);
  const supPageFilings = useSelector((state) => state?.survey?.supPageFilings);

  if (supPageFilings && supPageFilings.length > 0) {
    pageFilings = [...pageFilings, ...supPageFilings];
  }
  function PageFilingId(pageId) {
    const pageFiling = pageFilings.find((obj) => obj.pageId === pageId);
    return pageFiling?.id;
  }
  const surveyRespondentId = useSelector(
    (state) => state.survey.respondentDetails?.id
  );
  const openedQuestions = useSelector((state) => state.survey.openedQuestions);
  const questionGroups = useSelector(
    (state) => state.survey?.questionsInPage?.questionGroupDTOs
  );
  const supFilingId = useSelector(
    (state) => state?.survey?.supplementalFiling?.id
  );

  const supPages = useSelector((state) => state?.survey?.supPages);

  // const selectedQuestions = openedQuestions[page.id] || null;

  // const questionGroups = useSelector((state) => {
  //   if (selectedQuestions && selectedQuestions.length > 0) {
  //     return selectedQuestions;
  //   } else {
  //     return state.survey.questionsInPage;
  //   }
  // });

  let matchPageId = "";

  useEffect(() => {
    if (questionGroups) {
      matchPageId = questionGroups?.every((group) => group.page.id === page.id);
    }
  }, [questionGroups]);

  // const matchPageId = questionGroups?.every(
  //   (group) => group.page.id === page.id
  // );

  useEffect(() => {
    // if (!openedQuestions.hasOwnProperty(page.id)) {
    if (surveyId && surveyFilingId && page.id && surveyRespondentId) {
      let payload = {
        surveyId: surveyId,
        surveyFilingId: supPages.some((supPage) => supPage?.id === page.id)
          ? supFilingId
          : surveyFilingId,
        pageId: page.id,
        surveyRespondentId: surveyRespondentId,
        pageFilingId: PageFilingId(page.id),
        loopIndex: 1,
      };

      dispatch(fetchQuestionsInPage(payload));
      dispatch(setPagePayload(payload));
      dispatch(setShowErrorPage(false));
      if (location.pathname !== "/") {
        navigate("/");
      }
    }
    // }
  }, [page.id, dispatch]);

  useEffect(() => {
    // const fetchQuestionsData = async () => {
    //   try {
    //     const questionsData = await getQuestionGroups(page.id);
    //     const promises = Object.values(questionsData).map((data) => data);
    //     const responses = await Promise.all(promises);
    //     const responseData = responses[0].reduce(
    //       (acc, obj) => acc.concat(obj.questions),
    //       []
    //     );
    //     setQuestions(responseData);
    //   } catch (error) {
    //     console.error("Error fetching page data:", error);
    //   }
    // };
    // fetchQuestionsData();
    // if (allQuestions) {
    //   setQuestions(allQuestions);
    // }

    if (matchPageId) {
      const allQuestions = questionGroups.reduce(
        (accumulator, currentGroup) => {
          return accumulator.concat(currentGroup.questions);
        },
        []
      );

      setQuestions(allQuestions);
      dispatch(setQuestionsData({ pageId: page.id, data: questionGroups }));
    }
  }, [page, questionGroups]);

  const getLabel = (grp) => {
    if (grp.questions.length === 1) {
      if (grp.questions[0].label) {
        return grp.questions[0].label + grp.questions[0].name;
      } else {
        return grp.questions[0].name;
      }
    } else {
      if (grp.label) {
        return grp.label + grp.name;
      } else {
        return grp.name;
      }
    }
  };

  return (
    <>
      {questions.map((grp, index) => (
        <a
          href={`#${grp.id}`}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <QuestionTreeItem
            key={grp.id}
            selectIcon={<CropSquareIcon />}
            nodeId={`${index + 1}`}
            label={truncateText(
              `${getTextFromHTML(grp.label) || ""} ${
                getTextFromHTML(grp.name) || ""
              }`,
              35
            )}
          />
        </a>
      ))}
    </>
  );
}

function ExplorerTree({ section }) {
  const firstPageId = useSelector((state) => state.survey.allPages[0]?.id);
  const firstSectionId = useSelector(
    (state) => state.survey.sectionFilings?.[0]?.id
  );
  const [sectionData, setSectionData] = useState(section);
  const [expanded, setExpanded] = useState([]);
  const dispatch = useDispatch();
  const surveyRespondentId = useSelector(
    (state) => state.survey.respondentDetails?.id
  );
  const surveyId = useSelector((state) => state.survey.activeSurveys[0]?.id);
  const surveyFilingId = useSelector(
    (state) => state.survey.surveyFiling[0]?.id
  );
  const pageFilings = useSelector((state) => state.survey.pageFilings);
  const selectedSectionId = useSelector(
    (state) => state.survey.currentSelection.sectionId
  );
  const selectedPageId = useSelector(
    (state) => state.survey.currentSelection.pageId
  );

  function PageFilingId(pageId) {
    const pageFiling = pageFilings.find((obj) => obj.pageId === pageId);
    return pageFiling?.id;
  }

  useEffect(() => {
    if (surveyId && surveyRespondentId) {
      dispatch(
        fetchSurveyFiling({
          surveyId,
          surveyRespondentId,
        })
      );
    }
  }, [dispatch, surveyId, surveyRespondentId]);

  useEffect(() => {
    if (surveyFilingId) {
      dispatch(fetchPageFilingsBySurveyFilingId(surveyFilingId));
    }
  }, [dispatch, surveyFilingId]);

  // useEffect(() => {
  //   if (firstPageId && firstSectionId) {
  //     setExpanded(["3175", "2769"]);
  //   }
  // }, [firstPageId, firstSectionId]);

  useEffect(() => {
    if (selectedPageId && selectedSectionId) {
      setExpanded([selectedPageId.toString(), selectedSectionId.toString()]);
    }
  }, [selectedPageId, selectedSectionId]);

  useEffect(() => {
    if (section) {
      setSectionData(section);
    }
  }, [section]);

  // useEffect(() => {
  //   if (surveyId && surveyFilingId && firstPageId && surveyRespondentId && PageFilingId(firstPageId)) {
  //     // let payload = {
  //     //   surveyId: surveyId,
  //     //   surveyFilingId: surveyFilingId,
  //     //   pageId: firstPageId,
  //     //   surveyRespondentId: surveyRespondentId,
  //     //   pageFilingId: PageFilingId(firstPageId),
  //     //   loopIndex: 1,
  //     // }
  //     // dispatch(
  //     //   fetchQuestionsInPage(payload)
  //     // );
  //     // dispatch(setPagePayload(payload));
  //   }

  //   console.log(
  //     surveyId,
  //     surveyFilingId,
  //     firstPageId,
  //     surveyRespondentId,
  //     PageFilingId(firstPageId)
  //   );

  //   // dispatch(setDataIds({ secId: firstSectionId, pgId: firstPageId }));
  // }, [
  //   dispatch,
  //   firstPageId,
  //   firstSectionId,
  //   surveyFilingId,
  //   surveyRespondentId,
  //   PageFilingId(firstPageId)
  // ]);

  const handleNodeToggle = (event, nodes) => {
    setExpanded(nodes);
  };

  return (
    <div>
      {sectionData.map((section, index) => (
        <RootTreeView
          aria-label="customized"
          expanded={expanded}
          onNodeToggle={handleNodeToggle}
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          sx={{
            margin: "20px 0",
          }}
        >
          <SectionTreeItem
            key={section.section.id}
            selecticon={<SelectAllIcon />}
            nodeId={section.section.id.toString()}
            label={`Section ${truncateText(
              getTextFromHTML(section.section.name),
              25
            )}`}
            sx={{
              border: "1px solid transparent",
              borderRadius: "14px",
              [`& .${treeItemClasses.group}`]: {
                maxHeight: 250,
                overflowY: "auto",
                overflowX: "hidden",
                margin: "12px 12px",
                scrollbarWidth: "thin",
                scrollbarColor: "rgba(0, 48, 135, 1) white",
                "&::-webkit-scrollbar": {
                  width: 3,
                  backgroundColor: "white",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "rgba(0, 48, 135, 1)",
                  borderRadius: 4,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "transparent",
                },
              },
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <PageTreeItems section={section.section} index={index} />
          </SectionTreeItem>
        </RootTreeView>
      ))}
    </div>
  );
}

export default ExplorerTree;
