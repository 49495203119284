import React from "react";
import { Checkbox as MuiCheckbox } from "@mui/material";

const Checkbox = ({
  checked,
  onChange,
  value,
  historicalVal,
  ...otherProps
}) => {
  const color = historicalVal ? "maroon" : "";
  return (
    <MuiCheckbox
      checked={checked}
      onChange={onChange}
      value={value}
      sx={{
        color: color,
      }}
      {...otherProps}
    />
  );
};

export default Checkbox;
