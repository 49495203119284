import {
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import React from "react";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { htmlParser } from "../../Utility/constants";


const HelpTextPopper = ({question}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  const arrowStyles = {
    position: "absolute",
    fontSize: 7,
    width: "3em",
    height: "3em",
    "&::before": {
      content: '""',
      margin: "auto",
      display: "block",
      width: 0,
      height: 0,
      borderStyle: "solid",
    },
  };

  const [arrowRef, setArrowRef] = React.useState(null);

  return (
    <>
      <IconButton onClick={handleClick}>
        <ErrorOutlineIcon ml={2} color="primary"></ErrorOutlineIcon>
      </IconButton>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        disablePortal={false}
        modifiers={[
          {
            name: "flip",
            enabled: true,
            options: {
              altBoundary: true,
              rootBoundary: "document",
              padding: 8,
            },
          },
          {
            name: "preventOverflow",
            enabled: true,
            options: {
              altAxis: true,
              altBoundary: true,
              tether: true,
              rootBoundary: "document",
              padding: 8,
            },
          },
          {
            name: "arrow",
            enabled: true,
            options: {
              element: arrowRef,
            },
          },
        ]}
      >
        {<span className={arrowStyles} ref={setArrowRef} />}
        <Paper className="paperStyles">
          <DialogTitle sx={{ padding: "16px 24px 0px 24px" }}>
            <Typography variant="question">Help Text</Typography>
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClick}
            color="primary"
            sx={{
              position: "absolute",
              right: 15,
              top: 8,
            }}
          >
            <HighlightOffRoundedIcon />
          </IconButton>
          <DialogContent>
            <Typography variant="h6">
              {htmlParser(question?.helpText || "")}
            </Typography>
          </DialogContent>
        </Paper>
      </Popper>
    </>
  );
};

export default HelpTextPopper;
