import React from "react";
import { Button as MuiButton } from "@mui/material";

const Button = (props) => {
  const { height, width, borderRadius, color, ...otherProps } = props;

  // const customStyles = {
  //   height: height || "60px",
  //   width: width || "auto",
  //   borderRadius: borderRadius || "12px",
  // };



  return (
    <MuiButton
      variant="contained"
      color={color || "primary"}
      // sx={{
      //   ...customStyles,
      // }}
      {...otherProps}
    >
      {props.children}
    </MuiButton>
  );
};

export default Button;
