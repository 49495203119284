import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import { StyledEngineProvider } from "@mui/material/styles";
import "./QuestionTypes.scss";
// import List from "@mui/material/List";
// import CardHeader from "@mui/material/CardHeader";
// import ListItem from "@mui/material/ListItem";
// import ListItemText from "@mui/material/ListItemText";
// import ListItemIcon from "@mui/material/ListItemIcon";
// import Checkbox from "@mui/material/Checkbox";
// import Button from "@mui/material/Button";
// import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useSelector } from "react-redux";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// function not(a, b) {
//   return a.filter((value) => b.indexOf(value) === -1);
// }

// function intersection(a, b) {
//   return a.filter((value) => b.indexOf(value) !== -1);
// }

// function union(a, b) {
//   return [...a, ...not(b, a)];
// }

const StatesQuestion = ({
  value,
  question,
  options,
  fieldConfig,
  onChange,
  error,
  disabledField,
  ...otherProps
}) => {
  const [states, setStates] = useState([]);
  const countryCodes = useSelector((state) => state.survey.countryCodes);

  options = [
    { id: 1, value: "Alabama" },
    { id: 2, value: "Alaska" },
    { id: 3, value: "Arizona" },
    { id: 4, value: "Arkansas" },
    { id: 5, value: "California" },
    { id: 6, value: "Colorado" },
    { id: 7, value: "Connecticut" },
    { id: 8, value: "Delaware" },
  ];

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setStates(typeof value === "string" ? value.split(",") : value);
  };

  const getStateArray = (value) => {
    console.log(value);
    const resultArray = value
      .split("|")
      .filter((item) => item !== null && item !== undefined && item !== "");
    const arrayOfCodes = resultArray.map((id) => {
      const matchingObject = countryCodes.find((obj) => obj.id === Number(id));
      return matchingObject ? matchingObject.shortName : null; // Handle cases where no matching object is found
    });

    return arrayOfCodes;
  };

  useEffect(() => {
    if (value) {
      const stateArray = getStateArray(value);
      setStates(stateArray);
    }
  }, [value, question]);

  const selectedStateIds = countryCodes
    .filter((option) => states.includes(option.shortName))
    .map((option) => option.id);
  const savedAnswer =
    selectedStateIds.length > 0 ? selectedStateIds.join("|") + "|" : "";

  const handleBlur = () => {
    if (savedAnswer) onChange(savedAnswer);
  };
  return (
    <Grid sx={{ display: "flex" }} direction="column">
      <Grid sx={{ display: "flex" }} direction="row" alignItems="center">
        {question?.hideField ? (
          <></>
        ) : (
          <>
            <FormControl
              sx={{ m: 1, width: 300 }}
              disabled={
                otherProps?.type === "preview" || disabledField ? true : false
              }
            >
              <Select
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={states}
                onChange={handleChange}
                onBlur={handleBlur}
                input={<OutlinedInput id="select-multiple-chip" />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {countryCodes.map((option) => (
                  <MenuItem key={option.id} value={option.shortName}>
                    {option.longName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {otherProps.errorIndicator?.displayIndicator && (
              <Grid pl={2} style={{ color: "maroon" }}>{`<< `}</Grid>
            )}
          </>
        )}
      </Grid>
      <Grid>
        {question?.historicalAnswer?.value && (
          <Grid alignSelf={"start"} style={{ color: "maroon" }}>
            {getStateArray(question?.historicalAnswer?.value).join(",")}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default StatesQuestion;
