import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { TableCell as MuiTableCell } from "@mui/material/";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Divider, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import styled from "@emotion/styled";
import { getSurveyProgressReport } from "./Survey/Survey.service";
import { getTextFromHTML, htmlParser } from "../Utility/constants";

const cellBorderStyle = {
  borderRight: "1px solid rgba(224, 224, 224, 1)",
};
const TableCell = styled(MuiTableCell)({
  paddingLeft: "0",
  paddingRight: "0",
  ...cellBorderStyle,
});

function createData(
  sectionId,
  sectionName,
  statusName,
  percentCompleted,
  lastModified,
  modifiedBy,
  pages,
  pageFilings
) {
  const pagesData = pages?.map((page) => {
    const pageId = page.id;

    const pageFiling = pageFilings?.find((obj) => obj.pageId === pageId);

    return {
      name: page?.name,
      statusName: pageFiling?.status?.name,
      lastModified: dayjs
        .utc(pageFiling?.dateModified)
        .local()
        .format("MM-DD-YYYY hh:mm A"),
      modifiedBy: pageFiling?.modifiedBy,
    };
  });

  return {
    sectionId,
    sectionName,
    statusName,
    percentCompleted,
    lastModified,
    modifiedBy,
    pagesData,
  };
}

function TableRows(props) {
  const { row, progressReport, parentIndex } = props;
  const [open, setOpen] = useState(false);

  return (
    <>
      <Table>
        <TableBody>
          <TableRow
            sx={{
              "& > *": {
                backgroundColor:
                  parentIndex % 2 === 0 ? "#eeeeee" : "transparent",
              },
            }}
          >
            <TableCell>
              <Typography
                style={{
                  width: "50px",
                }}
              >
                {row?.pagesData?.length > 1 && (
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen(!open)}
                  >
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                )}
              </Typography>
            </TableCell>

            <TableCell>
              <Typography
                style={{
                  width: "200px",
                  padding: "10px",
                }}
              >
                {getTextFromHTML(row?.sectionName)}
              </Typography>
            </TableCell>

            <TableCell>
              <Typography
                style={{
                  width: "200px",
                  padding: "10px",
                }}
              >
                {row?.statusName}
              </Typography>
            </TableCell>

            <TableCell>
              <Typography
                style={{
                  width: "200px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {row?.percentCompleted}
              </Typography>
            </TableCell>

            <TableCell>
              <Typography
                style={{
                  width: "200px",
                  padding: "10px",
                }}
              >
                {row?.lastModified}
              </Typography>
            </TableCell>

            <TableCell>
              <Typography
                style={{
                  width: "200px",
                  padding: "10px",
                }}
              >
                {row?.modifiedBy}
              </Typography>
            </TableCell>

            {progressReport?.surveyRespondents?.map((user) => {
              const isSectionAssigned = user?.sectionAssignments?.some(
                (section) => section?.sectionId === row?.sectionId
              );

              const lastModifiedSection = user.sectionAssignments.find(
                (section) => section.sectionId === row.sectionId
              )?.lastModifiedSection;

              const isNotStarted =
                lastModifiedSection === null || row?.lastModified === null;

              return (
                <TableCell>
                  {isSectionAssigned ? (
                    <Typography
                      style={{
                        width: "200px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: "10px",
                      }}
                    >
                      {<CheckBoxIcon color="success" />}
                      {isNotStarted
                        ? "Not started"
                        : `Last modified: ${dayjs
                            .utc(lastModifiedSection)
                            .local()
                            .format("MM-DD-YYYY hh:mm A")}`}
                    </Typography>
                  ) : (
                    <Typography
                      style={{
                        width: "200px",
                        textAlign: "center",
                        padding: "10px",
                      }}
                    >
                      N/A
                    </Typography>
                  )}
                </TableCell>
              );
            })}
          </TableRow>
        </TableBody>
      </Table>

      <Table>
        <TableBody>
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Table>
                  <TableBody>
                    {row?.pagesData?.length > 1 &&
                      row?.pagesData?.map((pageData, index) => (
                        <TableRow
                          sx={{
                            "& > *": {
                              backgroundColor:
                                parentIndex % 2 === 0
                                  ? index % 2 === 0
                                    ? "transparent"
                                    : "#eeeeee"
                                  : index % 2 === 0
                                  ? "#eeeeee"
                                  : "transparent",
                            },
                          }}
                        >
                          <TableCell>
                            <Typography
                              style={{
                                width: "50px",
                              }}
                            ></Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              style={{
                                width: "200px",
                                padding: "10px",
                              }}
                            >
                              {`Page ${index + 1}: `}
                              {htmlParser(getTextFromHTML(pageData?.name))}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              style={{
                                width: "200px",
                                padding: "10px",
                              }}
                            >
                              {pageData?.statusName}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              style={{
                                width: "200px",
                              }}
                            ></Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              style={{
                                width: "200px",
                                padding: "10px",
                              }}
                            >
                              {pageData?.lastModified}
                            </Typography>
                          </TableCell>

                          <TableCell>
                            <Typography
                              style={{
                                width: "200px",
                                padding: "10px",
                              }}
                            >
                              {pageData?.modifiedBy}
                            </Typography>
                          </TableCell>

                          {progressReport?.surveyRespondents?.map((user) => (
                            <TableCell>
                              <Typography
                                style={{
                                  width: "200px",
                                }}
                              ></Typography>
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Collapse>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
}

export default function UsersProgress() {
  const [progressReport, setProgressReport] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  let allPages = useSelector((state) => state?.survey?.allPages);
  const supPages = useSelector((state) => state?.survey?.supPages);

  if (supPages && supPages.length > 0) {
    allPages = [...allPages, ...supPages];
  }

  const surveyFilingId = useSelector(
    (state) => state?.survey?.surveyFilingDto?.id
  );

  const supFilingId = useSelector(
    (state) => state.survey?.supplementalFiling?.id
  );

  const rows = progressReport?.sectionFilings?.map((filing) => {
    const pages = allPages?.filter(
      (obj) => obj.section.id === filing?.section?.id
    );
    const sectionId = filing?.section?.id;
    const sectionName = filing?.section?.name;
    const statusName = filing?.status?.name;
    const percentCompleted = `${filing?.percentPagesCompleted}%`;
    const lastModified = dayjs
      .utc(filing?.dateModified)
      .local()
      .format("MM-DD-YYYY hh:mm A");
    const modifiedBy = filing?.modifiedBy;

    return createData(
      sectionId,
      sectionName,
      statusName,
      percentCompleted,
      lastModified,
      modifiedBy,
      pages,
      progressReport?.pageFilings
    );
  });

  useEffect(() => {
    const fetchReport = async () => {
      const response = await getSurveyProgressReport(surveyFilingId);
      setProgressReport(response?.data);
      setDataLoaded(true);
    };
    fetchReport();
  }, [surveyFilingId]);

  useEffect(() => {
    const fetchSupReport = async () => {
      if (supFilingId && dataLoaded) {
        const supResponse = await getSurveyProgressReport(supFilingId);
        setProgressReport((prevProgressReport) => ({
          ...prevProgressReport,
          sectionFilings: [
            ...prevProgressReport.sectionFilings,
            ...supResponse.data.sectionFilings,
          ],
          pageFilings: [
            ...prevProgressReport.pageFilings,
            ...supResponse.data.pageFilings,
          ],
        }));
      }
    };

    fetchSupReport();
  }, [supFilingId, dataLoaded]);

  return (
    <Box>
      <Typography sx={{ fontSize: 20, fontWeight: "bold" }} color="primary">
        Survey Progress Report
      </Typography>
      <Divider sx={{ backgroundColor: "black" }} />
      <div style={{ display: "flex", marginBottom: "20px" }}>
        <Typography sx={{ marginRight: "50px" }}>
          Overall Survey Status: {progressReport?.surveyFiling?.status?.name}
        </Typography>
        <Typography>
          Overall % Survey Completion:{" "}
          {progressReport?.surveyFiling?.percentPagesCompleted}%
        </Typography>
      </div>
      <Typography
        sx={{ marginBottom: "20px", display: "flex", alignItems: "center" }}
      >
        <b>Survey Progress, By Section.</b> Note:{" "}
        {<CheckBoxIcon color="success" />} means the individual is assigned to
        the given section; “N/A” indicates the person is not assigned to that
        section.
      </Typography>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography
                  style={{
                    width: "50px",
                    padding: "10px",
                  }}
                ></Typography>
              </TableCell>
              <TableCell>
                <Typography
                  style={{
                    width: "200px",
                    padding: "10px",
                  }}
                >
                  Section
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  style={{
                    width: "200px",
                    padding: "10px",
                  }}
                >
                  Status
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  style={{
                    width: "200px",
                    padding: "10px",
                  }}
                >
                  % Completed
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  style={{
                    width: "200px",
                    padding: "10px",
                  }}
                >
                  Last Modified
                </Typography>
              </TableCell>

              <TableCell>
                <Typography
                  style={{
                    width: "200px",
                    padding: "10px",
                  }}
                >
                  Last Modified By
                </Typography>
              </TableCell>

              {progressReport?.surveyRespondents?.map((user) => (
                <TableCell>
                  <Typography
                    style={{
                      width: "200px",
                      padding: "10px",
                    }}
                  >
                    {`${user?.firstName} ${user?.lastName}`}
                    <br />
                    {user?.email}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        </Table>

        {rows?.map((row, index) => (
          <TableRows
            parentIndex={index}
            row={row}
            progressReport={progressReport}
          />
        ))}
      </TableContainer>
    </Box>
  );
}
