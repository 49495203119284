import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Card,
  Chip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Divider from "@mui/material/Divider";
import { Button } from "@mui/material";
import {
  fetchQuestionsInPage,
  setShowErrorPage,
  setDataIds,
  setShowLanding,
  fetchNextPage,
  setPagePayload,
} from "../../pages/Survey/Survey.slice";
import HelpTextPopper from "../../components/Common/Popper";
import Home from "../Home/HomePage";
import { htmlParser } from "../../Utility/constants";

const ErrorListPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const errorData = useSelector((state) => state.survey?.errorPageDetails);
  const errorTrigger = useSelector(
    (state) => state.survey?.errorPageDetails?.trigger
  );

  // useEffect(() => {
  //   console.log("the error data in the error list is: ", errorData);
  // }, [errorData]);

  let sectionFilings = useSelector((state) => state.survey.sectionFilings);
  const supSectionFiling = useSelector(
    (state) => state?.survey?.supSectionFiling
  );

  if (supSectionFiling && supSectionFiling.length > 0) {
    sectionFilings = [...sectionFilings, ...supSectionFiling];
  }

  let allPages = useSelector((state) => state?.survey?.allPages);
  const supPages = useSelector((state) => state?.survey?.supPages);

  if (supPages && supPages.length > 0) {
    allPages = [...allPages, ...supPages];
  }

  const selectedSectionId = useSelector(
    (state) => state.survey.errorPageDetails.sectionId
  );
  const selectedPageId = useSelector(
    (state) => state.survey?.errorPageDetails?.pageId
  );
  const SectionData = sectionFilings.find(
    (obj) => obj?.section?.id === selectedSectionId
  );
  const PageData = allPages.find((obj) => obj.id === selectedPageId);
  const showErrorPage = useSelector((state) => state.survey?.showErrorPage);
  const pagePayload = useSelector((state) => state.survey.pagePayload);
  const surveyId = useSelector((state) => state.survey.activeSurveys[0]?.id);
  let pageFilings = useSelector((state) => state.survey.pageFilings);
  const supPageFilings = useSelector((state) => state?.survey?.supPageFilings);

  if (supPageFilings && supPageFilings.length > 0) {
    pageFilings = [...pageFilings, ...supPageFilings];
  }

  // useEffect(() => {
  //   console.log('the section data and page data is: ', SectionData, PageData, errorData);
  // }, [SectionData, PageData]);

  const surveyRespondentId = useSelector(
    (state) => state.survey.respondentDetails?.id
  );
  const handleBack = () => {
    dispatch(fetchQuestionsInPage(pagePayload));
    dispatch(setShowErrorPage(false));
  };

  const handleBackToHome = () => {
    dispatch(setShowLanding(true));
  };

  const handleCorrectLater = () => {
    dispatch(
      fetchNextPage({
        pageFilingId: pagePayload?.pageFilingId,
        surveyRespondentId: surveyRespondentId,
      })
    ).then((data) => {
      // call display page api and set the sidebar
      if (data?.payload?.pageId) {
        let payload = { ...pagePayload };
        payload.pageId = data?.payload?.pageId;
        payload.pageFilingId = data?.payload?.id;
        dispatch(fetchQuestionsInPage(payload));
        dispatch(
          setDataIds({
            secId: data?.payload?.sectionFiling?.sectionId,
            pgId: data?.payload?.pageId,
          })
        );
      } else if (data === null || (data && !data.payload)) {
        dispatch(setShowLanding(true));
      }
    });
  };

  let sectionLevel = false;
  let pageLevel = false;
  let surveyLevel = false;
  let allErrors = false;

  if (errorTrigger === "errorChip") {
    sectionLevel = true;
  } else if (errorTrigger === "errorLink") {
    allErrors = true;
  } else {
    if (errorData?.pageId !== null && errorData?.pageFilingId !== null) {
      sectionLevel = true;
      pageLevel = true;
    } else if (
      errorData?.sectionFilingId !== null &&
      errorData?.sectionId !== null &&
      errorData?.pageId === null &&
      errorData?.pageFilingId === null
    ) {
      sectionLevel = true;
    } else if (
      errorData?.sectionFilingId === null &&
      errorData?.sectionId === null &&
      errorData?.pageId === null &&
      errorData?.pageFilingId === null &&
      errorData?.surveyFilingId !== null
    ) {
      surveyLevel = true;
    }
  }

  const correctionClicked = (error) => {
    function PageFilingId(pageId) {
      const pageFiling = pageFilings.find((obj) => obj.pageId === pageId);
      return pageFiling?.id;
    }

    let payload = {
      surveyId: surveyId,
      surveyFilingId: error?.surveyFiling.id,
      pageId: error?.page?.id ? error?.page?.id : selectedPageId,
      surveyRespondentId: surveyRespondentId,
      pageFilingId: PageFilingId(
        error?.page?.id ? error?.page?.id : selectedPageId
      ),
      loopIndex: 1,
    };

    dispatch(setPagePayload(payload));
    navigate(`/error-correction/${error.id}`, {
      state: { errorText: error.rule },
    });
  };

  return (
    <>
      {showErrorPage ? (
        <>
          <div
            style={{
              display: "flex",
              margin: "0 20px 20px 20px ",
              justifyContent: "space-between",
            }}
          >
            <div>
              {sectionLevel && (
                <div style={{ display: "flex" }}>
                  <Typography
                    sx={{ fontSize: 25, fontWeight: "bold" }}
                    color="primary"
                  >
                    {htmlParser(SectionData?.section?.name)}
                  </Typography>
                  {SectionData?.section?.helpText && (
                    <HelpTextPopper
                      question={SectionData?.section}
                    ></HelpTextPopper>
                  )}
                </div>
              )}

              {pageLevel && (
                <>
                  <Divider sx={{ backgroundColor: "black", width: "100%" }} />
                  <div style={{ display: "flex" }}>
                    <Typography
                      sx={{ fontSize: 20, fontWeight: "bold" }}
                      color="primary"
                    >
                      {htmlParser(PageData?.name)}
                    </Typography>
                    {PageData?.helpText && (
                      <HelpTextPopper question={PageData}></HelpTextPopper>
                    )}
                  </div>
                </>
              )}

              {surveyLevel && (
                <div style={{ display: "flex" }}>
                  <Typography
                    sx={{ fontSize: 25, fontWeight: "bold" }}
                    color="primary"
                  >
                    Cross-Section Errors
                  </Typography>
                </div>
              )}

              {allErrors && (
                <div style={{ display: "flex" }}>
                  <Typography
                    sx={{ fontSize: 25, fontWeight: "bold" }}
                    color="primary"
                  >
                    All Errors
                  </Typography>
                </div>
              )}
            </div>

            {allErrors ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Button
                  variant="contained"
                  color={"primary"}
                  sx={{ mr: 1 }}
                  onClick={() => handleBackToHome()}
                >
                  Back to Home Page
                </Button>
              </div>
            ) : (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Button
                  variant="contained"
                  color={"primary"}
                  sx={{ mr: 1 }}
                  onClick={() => handleBack()}
                >
                  Back to Survey Page
                </Button>
                <Button
                  variant="contained"
                  color={"primary"}
                  sx={{ mr: 1 }}
                  onClick={() => handleCorrectLater()}
                >
                  Correct Errors Later
                </Button>
              </div>
            )}
          </div>

          <div style={{ margin: "20px 20px" }}>
            <Divider sx={{ backgroundColor: "black" }} />
            <Typography variant="headerMenu">
              The following errors have occured during validation
            </Typography>
          </div>
          <div style={{ margin: "20px 20px 40px 20px" }}>
            <TableContainer
              component={Card}
              sx={{
                // margin: "0 20px 40px 20px",
                borderRadius: "16px",
                boxShadow: "0px 4px 6px 0px rgba(62, 73, 84, 0.04)",
              }}
            >
              <Table>
                <TableBody>
                  {errorData?.errors?.map((error, index) => (
                    <React.Fragment key={index}>
                      <TableRow>
                        <TableCell
                          style={{ width: "80%", padding: "15px 30px " }}
                        >
                          <Typography style={{ color: "maroon" }}>
                            {`${error?.rule?.errorText} [Ref.# ${error?.rule?.id}]`}
                          </Typography>
                        </TableCell>
                        <TableCell style={{ padding: "15px 30px " }}>
                          <Typography
                            style={{
                              fontWeight: "bold",
                              textDecoration: "none",
                              color: "inherit",
                              cursor: "pointer",
                            }}
                            onMouseOver={(e) =>
                              (e.target.style.color = "#003087")
                            }
                            onMouseOut={(e) =>
                              (e.target.style.color = "inherit")
                            }
                            onClick={() => correctionClicked(error)}
                          >
                            Correct now
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </>
      ) : (
        <Home />
      )}
    </>
  );
};

export default ErrorListPage;
