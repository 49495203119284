import React, { useState } from "react";
import CommentModal from "./CommentModal";
import { useSelector } from "react-redux";
import { saveComment } from "../../pages/Survey/Survey.service";

const withCommentModal = (WrappedComponent) => {
  return (props) => {
    const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
    const [quesId, setQuesId] = useState(null);
    const [pageId, setPageId] = useState(null);
    const [secId, setsecId] = useState(null);

    const surveyFilingDto = useSelector(
      (state) => state.survey?.surveyFilingDto
    );

    const builderOrgId = useSelector((state) => state.survey?.builderOrgId);

    const handleOpenCommentModal = (secId, pageId, quesId) => {
      setQuesId(quesId);
      setPageId(pageId);
      setsecId(secId);
      setIsCommentModalOpen(true);
    };

    const handleCloseCommentModal = () => {
      setIsCommentModalOpen(false);
      setQuesId(null);
      setPageId(null);
      setsecId(null);
    };

    const handleSaveComment = async (comment) => {
      try {
        await saveComment({
          surveyFiling: surveyFilingDto,
          orgId: builderOrgId,
          sectionId: secId,
          quesId: quesId,
          pageId: pageId,
          text: comment,
        });
        handleCloseCommentModal();
      } catch (error) {
        console.error("Error posting comment:", error);
      }
    };

    return (
      <>
        <WrappedComponent
          {...props}
          openCommentModal={handleOpenCommentModal}
        />
        <CommentModal
          isOpen={isCommentModalOpen}
          onClose={handleCloseCommentModal}
          onSave={handleSaveComment}
        />
      </>
    );
  };
};

export default withCommentModal;
