import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { Button, Typography } from "@mui/material";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import theme from "../../theme";

const CommentModal = ({ isOpen, onClose, onSave }) => {
  const [comment, setComment] = useState("");
  const [commentError, setCommentError] = useState("");

  useEffect(() => {
    if (!isOpen) {
      setCommentError("");
      setComment("");
    }
  }, [isOpen]);

  const handleSave = () => {
    if (!comment.trim()) {
      setCommentError("Please enter a comment.");
      return;
    }
    onSave(comment);
    setComment("");
    onClose();
  };

  const modalStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const bodyStyle = {
    backgroundColor: "white",
    padding: "30px",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    minWidth: "30vw",
  };

  return (
    <Modal open={isOpen} onClose={onClose} style={modalStyle}>
      <div style={bodyStyle}>
        <Typography
          sx={{ fontSize: 20, fontWeight: "bold", marginBottom: "10px" }}
          color="primary"
        >
          Add comment
        </Typography>
        <TextareaAutosize
          minRows={8}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          style={{ width: "100%", marginBottom: "20px" }}
        />
        {commentError && (
          <Typography color={theme.palette.error.main}>
            {commentError}
          </Typography>
        )}
        <div>
          <Button
            variant="contained"
            onClick={onClose}
            style={{ marginRight: "15px" }}
          >
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CommentModal;
