import parse from "html-react-parser";

export const LOCAL_STORAGE_KEYS = {
  TOKEN_KEY: "authToken",
};

const options = {
  replace: (domNode) => {
    const extractText = (node) => {
      if (node.type === "text") {
        return node.data || "";
      } else if (node.children) {
        return node.children.map(extractText).join("");
      } else {
        return "";
      }
    };

    if (domNode.attribs && domNode.attribs.class === "remove") {
      return extractText(domNode);
    }
  },
};

export const htmlParser = (htmlString) => {
  return parse(htmlString ? `${htmlString}` : "", options);
};

export const getTextFromHTML = (htmlString) => {
  if (htmlString) {
    htmlString = htmlString.replace(/<\/(li|p|td|th|tr|div)>/g, " </$1>");
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlString;
    let textContent = tempDiv.textContent || tempDiv.innerText || "";
    textContent = textContent.replace(/\s+/g, " ").trim();
    return textContent;
  } else return "";
};

// export const BASE_URL = 'https://api.stg.survey-one-apis.com/api/v1';
//export const BASE_URL = 'https://api.dev.survey-one-apis.com/api/v1';
// export const BASE_URL = 'http://localhost:8080/api/v1';

export const BASE_URL = process.env.REACT_APP_BASE_URL + "/api/v1";

export const APP_URL = process.env.REACT_APP_BASE_URL;
