import React, { useEffect, useState } from "react";
import { Grid, TextField as Input } from "@mui/material";
import Tooltip from "./Tooltip";

const TextField = (props) => {
  const {
    question,
    height,
    value,
    size,
    onChange,
    placeholder,
    maxLength,
    disabledField,
    helperUserOnChange,
    readOnlyField,
    ruleErrorText,
    ...otherProps
  } = props;

  const [text, setText] = useState("");
  const [error, setError] = useState(null);

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setText(inputValue);
    if (helperUserOnChange) {
      onChange(inputValue);
    }
    if (maxLength && inputValue.length > maxLength) {
      setError(`Maximum value is ${maxLength}.`);
    } else {
      setError(null);
      if (inputValue !== "") {
        // onChange(inputValue);
      }
    }
  };

  useEffect(() => {
    setText(value);
  }, [value, question]);

  const handleInputBlur = (event) => {
    onChange(event.target.value);
  };

  return (
    <Grid sx={{ display: "flex" }} direction="row" alignItems="center">
      {question?.hideField ? (
        <></>
      ) : (
        <>
          {ruleErrorText && (
            <Tooltip title={ruleErrorText}>
              <Input
                size={height}
                disabled={
                  otherProps?.type === "preview" || disabledField ? true : false
                }
                value={text}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                placeholder={placeholder || ""}
                error={!!error}
                helperText={error}
                variant="outlined"
                sx={{
                  "& ::placeholder": {
                    color: "rgba(215, 215, 215, 1)",
                  },
                  width: size ? `${size * 8}px` : "100%",
                }}
                InputProps={readOnlyField ? { readOnly: true } : {}}
                {...otherProps}
              />
            </Tooltip>
          )}
          {!ruleErrorText && (
            <Input
              size={height}
              disabled={
                otherProps?.type === "preview" || disabledField ? true : false
              }
              value={text}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              placeholder={placeholder || ""}
              error={!!error}
              helperText={error}
              variant="outlined"
              sx={{
                "& ::placeholder": {
                  color: "rgba(215, 215, 215, 1)",
                },
                width: size ? `${size * 8}px` : "100%",
              }}
              InputProps={readOnlyField ? { readOnly: true } : {}}
              {...otherProps}
            />
          )}
          {otherProps.errorIndicator?.displayIndicator && (
            <Grid pl={2} style={{ color: "maroon" }}>{`<< `}</Grid>
          )}
        </>
      )}
    </Grid>
  );
};

export default TextField;
