import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { Grid } from "@mui/material";

const PhoneField = (props) => {
  const {
    question,
    height,
    value,
    size,
    onChange,
    placeholder,
    min,
    max,
    maxDigits,
    allowDecimal,
    precision,
    disabledField,
    ...otherProps
  } = props;

  const formatWithCommas = (value) => {
    if (value === "" || value === null || isNaN(value)) {
      return "";
    }

    let [integerPart, fractionalPart] = value.split(".");
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    if (fractionalPart !== undefined) {
      return `${integerPart}.${fractionalPart}`;
    }

    return integerPart;
  };


  const maskPhoneNumber = (phone) => {
    if (!phone) return phone;
    const phoneNumber = phone.replace(/[^\d]/g, "");
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  };

  const [error, setError] = useState(null);
  const [numericValue, setNumericValue] = useState(
    value && value !== undefined ? String(value) : ""
  );

  const handleInputChange = (event) => {
    const inputValue = event.target.value.replace(/[^0-9.]/g, "");

    setNumericValue(inputValue);

    if (!allowDecimal && inputValue.includes(".")) {
      setError("Decimal not allowed.");
    } else if (
      maxDigits !== undefined &&
      inputValue.replace(/\D/g, "").length > maxDigits
    ) {
      setError(`Value can have max ${maxDigits} digits.`);
    } else if (min !== undefined && inputValue < min && inputValue !== "") {
      setError(`Minimum value is  ${min}.`);
    } else if (max !== undefined && inputValue > max) {
      setError(`Maximum value is ${max}.`);
    } else {
      setError(null);
      // if (onChange && inputValue !== "" && inputValue.length <= 10) {
      //   onChange(inputValue);
      // }
    }
  };

  const handleInputBlur = (event) => {
    if (numericValue.length <= 10) onChange(numericValue);
  };

  useEffect(() => {
    if (value && value !== "") setNumericValue(String(value));
  }, [value, question]);

  return (
    <Grid sx={{ display: "flex" }} direction="column">
      <Grid sx={{ display: "flex" }} direction="row" alignItems="center">
        <TextField
          size={height}
          disabled={
            otherProps?.type === "preview" || disabledField ? true : false
          }
          type="text"
          value={maskPhoneNumber(numericValue)}
          variant="outlined"
          onBlur={handleInputBlur}
          onChange={handleInputChange}
          placeholder={placeholder || ""}
          error={!!error}
          helperText={error}
          sx={{
            "& ::placeholder": {
              color: "rgba(215, 215, 215, 1)",
            },
            // mt: 1,
            width: size ? `${size * 8}px` : "100%",
          }}
          {...otherProps}
        />
        {otherProps.errorIndicator?.displayIndicator && (
          <Grid pl={2} style={{ color: "maroon" }}>{`<< `}</Grid>
        )}
      </Grid>
      <Grid>
        {question?.historicalAnswer?.value && (
          <Grid alignSelf={"start"} style={{ color: "maroon" }}>
            {question?.historicalAnswer?.value}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default PhoneField;
