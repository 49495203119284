import React from "react";
import { formatWithCommas } from "../Common/NumericField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import "./QuestionTypes.scss";
import TotalField from "../Common/TotalField";

const TotalCalcQes = ({
  question,
  fieldConfig,
  onChange,
  error,
  height,
  disabledField,
  total,
  ...otherProps
}) => {
  return (
    <>
      <Grid item>
        <TotalField
          height={height}
          type={otherProps.type}
          value={
            total !== undefined ? total : ""
          }
          disabledField={disabledField}
          maxDigits={question?.maxDigits}
          min={question?.minNumeric}
          max={question?.maxNumeric !== 0 ? question?.maxNumeric : undefined}
          size={question?.size}
          allowDecimal={question?.isFloat}
          precision={question?.numPrecision}
          onChange={onChange}
          question={question}
          // error={error}
          {...otherProps}
        />
        <div style={{ textAlign: "start" }}>
          <Typography
            sx={{ color: "maroon", mt: "-2px", alignSelf: "flex-start" }}
          >
            {question?.historicalAnswer?.value
              ? formatWithCommas(question?.historicalAnswer?.value)
              : ""}
          </Typography>
        </div>
      </Grid>
    </>
  );
};

export default TotalCalcQes;
