import React from "react";
import NumericField from "../Common/NumericField";
import { formatWithCommas } from "../Common/NumericField";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import { StyledEngineProvider } from "@mui/material/styles";
import "./QuestionTypes.scss";

const NumericInputQuestion = ({
  question,
  fieldConfig,
  onChange,
  error,
  height,
  disabledField,
  ...otherProps
}) => {
  return (
    <>
      <Grid item>
        <NumericField
          height={height}
          type={otherProps.type}
          value={
            question?.answer?.value !== undefined ? question.answer.value : ""
          }
          disabledField={disabledField}
          maxDigits={question?.maxDigits}
          min={question?.minNumeric}
          max={question?.maxNumeric !== 0 ? question?.maxNumeric : undefined}
          size={question?.size}
          allowDecimal={question?.isFloat}
          precision={question?.numPrecision}
          onChange={onChange}
          question={question}
          readOnlyField={question?.readOnlyField}
          ruleErrorText={
            question?.rule?.errorText ? question?.rule?.errorText : null
          }
          autoCommas={question?.autoCommas}
          {...otherProps}
        />
        <div style={{ textAlign: "start" }}>
          <Typography
            sx={{ color: "maroon", mt: "-2px", alignSelf: "flex-start" }}
          >
            {question?.historicalAnswer?.value
              ? formatWithCommas(question?.historicalAnswer?.value)
              : ""}
          </Typography>
        </div>
      </Grid>
    </>
  );
};

export default NumericInputQuestion;
