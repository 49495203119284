import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    text: {
      primary: "#232323",
    },
    primary: {
      main: "#003087",
      contrastText: "#fff",
    },
    secondary: {
      main: "#ff9800",
    },
    info: {
      main: "#fed141",
    },
    success: {
      main: "#008755",
    },
    warning: {
      main: "#e09f3e",
    },
    error: {
      main: "#d50032",
    },
    headerMenu: {
      main: "#717579",
    },
    primaryText: {
      main: "#171B1E",
    },
    sidebarMenuActive: {
      main: "#1b1ba7",
    },
    action: {
      disabled: "",
    },
  },
  typography: {
    fontFamily: "Arial",
    question: {
      fontWeight: "700",
      fontSize: "20px",
      lineHeight: "23px",
    },
    label: {
      fontWeight: "600",
      fontSize: "16px",
      lineHeight: "24px",
    },
    headerMenu: {
      fontWeight: "600",
      fontSize: "18px",
      lineHeight: "24px",
    },
    d1: {
      fontWeight: "900",
      fontSize: "54px",
      lineHeight: 1.2,
    },
    d2: {
      fontWeight: "700",
      fontSize: "40px",
      lineHeight: 1.3,
    },
    h1: {
      fontWeight: "700",
      fontSize: "44px",
      lineHeight: 1.3,
    },
    h2: {
      fontWeight: "700",
      fontSize: "36px",
      lineHeight: 1.3,
    },
    h3: {
      fontWeight: "400",
      fontSize: "28px",
      lineHeight: 1.3,
    },
    h4: {
      fontWeight: "400",
      fontSize: "24px",
      lineHeight: 1.3,
    },
    h5: {
      fontWeight: "400",
      fontSize: "20px",
      lineHeight: 1.3,
    },
    h6: {
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "24px",
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "&  .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled": {
            "-webkit-text-fill-color": "#232323",
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          "& .MuiFormControlLabel-label.Mui-disabled": {
            color: "#232323",
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-input.MuiOutlinedInput-input.MuiSelect-select.Mui-disabled":
            {
              "-webkit-text-fill-color": "#232323",
            },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          "&.Mui-disabled": {
            "-webkit-text-fill-color": "#232323",
          },
        },
      },
    },
  },
});

export default theme;
