import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import HomeIcon from "@mui/icons-material/Home";
import ExplorerTree from "./ExplorerTree";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useSelector } from "react-redux";
import { Button, Modal } from "@mui/material";

import GetComments from "../../components/BuilderViewEditRouter/GetComments";
function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

function CustomSeparator() {
  const selectedsectionId = useSelector(
    (state) => state.survey?.currentSelection?.sectionId
  );
  const selectedPageId = useSelector(
    (state) => state.survey?.currentSelection?.pageId
  );
  let sectionFilings = useSelector((state) => state?.survey?.sectionFilings);
  const supSectionFiling = useSelector(
    (state) => state?.survey?.supSectionFiling
  );

  if (supSectionFiling && supSectionFiling.length > 0) {
    sectionFilings = [...sectionFilings, ...supSectionFiling];
  }

  let allPages = useSelector((state) => state?.survey?.allPages);
  const supPages = useSelector((state) => state?.survey?.supPages);

  if (supPages && supPages.length > 0) {
    allPages = [...allPages, ...supPages];
  }
  const SectionData = sectionFilings?.find(
    (obj) => obj.id === selectedsectionId
  );
  const PageData = allPages?.find((obj) => obj.id === selectedPageId);

  function getSectionNumber(sequence) {
    if (sequence >= 0 && sequence <= 25) {
      return String.fromCharCode(65 + sequence);
    }
    return null;
  }

  const sectionNumber = getSectionNumber(SectionData?.sequence);
  const pageNumber = PageData?.sequence + 1;

  const breadcrumbs = [
    <Link
      style={{
        textDecoration: "none",
        color: "inherit",
      }}
      key="1"
      href="/"
      onClick={handleClick}
    >
      {`Section - ${sectionNumber}`}
    </Link>,
    <Link
      style={{
        textDecoration: "none",
        color: "inherit",
      }}
      key="2"
      href="/"
      onClick={handleClick}
    >
      {`Page - ${pageNumber}`}
    </Link>,
    // <Typography key="3" color="text.primary">
    //   Q1
    // </Typography>,
  ];

  return (
    <Stack spacing={2}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {sectionNumber && pageNumber && breadcrumbs}
      </Breadcrumbs>
    </Stack>
  );
}

const drawerWidth = 400;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Sidebar({ open, section }) {
  const navigate = useNavigate();
  const [openComments, setOpenComments] = useState(false);
  const isbuilderLogin = useSelector((state) => state?.survey?.isbuilderLogin);
  const isOpde = useSelector((state) => state?.survey?.isOpde);
  const isFromBuilder = isbuilderLogin || isOpde ? true : false;
  const surveyRespondentId = useSelector(
    (state) => state.survey.respondentDetails?.id
  );

  const HospitalName = useSelector(
    (state) => state.survey.respondentDetails?.surveyOrganization?.name
  );

  const ahaId = useSelector(
    (state) => state.survey.respondentDetails?.surveyOrganization?.customId
  );
  const showCommentsView = () => {
    setOpenComments(true);
  };
  const handleCloseCommentsModal = () => {
    setOpenComments(false);
  };
  return (
    <Box sx={{ display: "flex", position: "unset" }}>
      <CssBaseline />
      <Drawer
        sx={{ position: "unset !important" }}
        variant="permanent"
        open={open}
      >
        {/* <DrawerHeader></DrawerHeader>
        <Divider />
        <List>
          <ListItem
            disablePadding
            sx={{ display: "block" }}
            onClick={() => navigate("/")}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <HomeIcon/>
              </ListItemIcon>
              <ListItemText primary="Home" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            sx={{ display: "block" }}
            onClick={() => navigate("/about")}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <InfoIcon />
              </ListItemIcon>
              <ListItemText
                primary="About"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem
            disablePadding
            sx={{ display: "block" }}
            onClick={() => navigate("/contact")}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <ContactPageIcon />
              </ListItemIcon>
              <ListItemText
                primary="Contact"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
        </List> */}
        <Box
          sx={{
            backgroundColor: "rgba(251, 251, 251, 1)",
            padding: "20px 20px ",
            height: "90vh",
            overflowY: "auto",
            overflowX: "hidden",
            wordWrap: "break-word",
            whiteSpace: "normal",
            scrollbarWidth: "thin",
            scrollbarColor: "rgba(0, 48, 135, 1) white",
            "&::-webkit-scrollbar": {
              width: 3,
              backgroundColor: "white",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgba(0, 48, 135, 1)",
              borderRadius: 4,
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "transparent",
            },
          }}
        >
          <Typography color="primary">
            <b>
              {`${HospitalName ? HospitalName : ""} ${
                ahaId ? `(${ahaId})` : ""
              }`}
            </b>
          </Typography>
          {surveyRespondentId && (
            <Typography
              fontSize={30}
              fontWeight="bold"
              marginTop={2}
              marginBottom={2}
            >
              Survey Sections
            </Typography>
          )}
          <CustomSeparator />
          <Divider />
          <ExplorerTree section={section} />
          {isFromBuilder && (
            <Button
              variant="text"
              color="primary"
              onClick={() => showCommentsView()}
            >
              View Comments
            </Button>
          )}
        </Box>
        <Modal open={openComments} onClose={handleCloseCommentsModal}>
          <GetComments handleCloseComments={handleCloseCommentsModal} />
        </Modal>
      </Drawer>
    </Box>
  );
}
