import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Card,
  Chip,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Divider from "@mui/material/Divider";
import { Button } from "@mui/material";
import {
  fetchQuestionsInPage,
  setShowErrorPage,
  setDataIds,
  setShowLanding,
  fetchNextPage,
  setPagePayload,
} from "../../pages/Survey/Survey.slice";
import HelpTextPopper from "../../components/Common/Popper";
// import Home from "../Home/HomePage";
import { htmlParser } from "../../Utility/constants";

const OpdeErrorListPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const errorData = useSelector((state) => state.survey?.errorPageDetails);
  const errorTrigger = useSelector(
    (state) => state.survey?.errorPageDetails?.trigger
  );
  let sectionFilings = useSelector((state) => state.survey.sectionFilings);
  const supSectionFiling = useSelector(
    (state) => state?.survey?.supSectionFiling
  );

  if (supSectionFiling && supSectionFiling.length > 0) {
    sectionFilings = [...sectionFilings, ...supSectionFiling];
  }

  let allPages = useSelector((state) => state?.survey?.allPages);
  const supPages = useSelector((state) => state?.survey?.supPages);

  if (supPages && supPages.length > 0) {
    allPages = [...allPages, ...supPages];
  }

  const selectedSectionId = useSelector(
    (state) => state.survey?.errorPageDetails?.sectionId
  );
  const selectedPageId = useSelector(
    (state) => state.survey?.errorPageDetails?.pageId
  );
  const SectionData = sectionFilings.find(
    (obj) => obj?.section?.id === selectedSectionId
  );
  const PageData = allPages.find((obj) => obj.id === selectedPageId);
  const pagePayload = useSelector((state) => state.survey.pagePayload);
  const surveyId = useSelector((state) => state.survey.activeSurveys[0]?.id);
  let pageFilings = useSelector((state) => state.survey.pageFilings);
  const supPageFilings = useSelector((state) => state?.survey?.supPageFilings);

  if (supPageFilings && supPageFilings.length > 0) {
    pageFilings = [...pageFilings, ...supPageFilings];
  }

  const surveyRespondentId = useSelector(
    (state) => state.survey.respondentDetails?.id
  );
  const surveyFilingId = useSelector(
    (state) => state.survey.surveyFilingDto?.id
  );
  const builderOrgId = useSelector((state) => state.survey?.builderOrgId);

  const errorPageLoading = useSelector(
    (state) => state.survey?.errorPageLoading
  );

  // const handleBackToHome = () => {
  //   // dispatch(setShowLanding(true));
  //   navigate("/opde/349309");
  // };

  const handleCorrectLater = () => {
    navigate(`/opde/${surveyFilingId}/${builderOrgId}`);
  };

  let sectionLevel = false;
  let pageLevel = false;
  let errorLevel = null;

  if (errorData?.pageId !== null && errorData?.pageFilingId !== null) {
    sectionLevel = true;
    pageLevel = true;
  } else if (
    errorData?.sectionFilingId !== null &&
    errorData?.sectionId !== null &&
    errorData?.pageId === null &&
    errorData?.pageFilingId === null
  ) {
    sectionLevel = true;
  } else if (
    errorData?.sectionFilingId === null &&
    errorData?.sectionId === null &&
    errorData?.pageId === null &&
    errorData?.pageFilingId === null
  ) {
    if (errorData?.errorLevel === "page") {
      errorLevel = "Page-Level Errors";
    } else if (errorData?.errorLevel === "section") {
      errorLevel = "Section-Level Errors";
    } else if (errorData?.errorLevel === "survey") {
      errorLevel = "Cross-Section Errors";
    }
  }

  const correctionClicked = (error) => {
    function PageFilingId(pageId) {
      const pageFiling = pageFilings.find((obj) => obj.pageId === pageId);
      return pageFiling?.id;
    }

    let payload = {
      surveyId: surveyId,
      surveyFilingId: error?.surveyFiling.id,
      pageId: error?.page?.id ? error?.page?.id : selectedPageId,
      surveyRespondentId: surveyRespondentId,
      pageFilingId: PageFilingId(
        error?.page?.id ? error?.page?.id : selectedPageId
      ),
      loopIndex: 1,
    };

    dispatch(setPagePayload(payload));
    navigate(`/opde-error-correction/${error.id}`, {
      state: { errorText: error.rule },
    });
  };

  return errorPageLoading ? (
    <>
      <Box
        display="flex"
        color="primary"
        sx={{ height: "100vh", width: "100%", fontSize: "24px" }}
        alignItems="center"
        justifyContent="center"
      >
        Running validation rules…
      </Box>
    </>
  ) : (
    <>
      <div
        style={{
          display: "flex",
          margin: "0 20px 20px 20px ",
          justifyContent: "space-between",
        }}
      >
        <div>
          {sectionLevel && (
            <div style={{ display: "flex" }}>
              <Typography
                sx={{ fontSize: 25, fontWeight: "bold" }}
                color="primary"
              >
                {htmlParser(SectionData?.section?.name)}
              </Typography>
              {SectionData?.section?.helpText && (
                <HelpTextPopper
                  question={SectionData?.section}
                ></HelpTextPopper>
              )}
            </div>
          )}

          {pageLevel && (
            <>
              <Divider sx={{ backgroundColor: "black", width: "100%" }} />
              <div style={{ display: "flex" }}>
                <Typography
                  sx={{ fontSize: 20, fontWeight: "bold" }}
                  color="primary"
                >
                  {htmlParser(PageData?.name)}
                </Typography>
                {PageData?.helpText && (
                  <HelpTextPopper question={PageData}></HelpTextPopper>
                )}
              </div>
            </>
          )}

          {errorLevel && (
            <div style={{ display: "flex" }}>
              <Typography
                sx={{ fontSize: 25, fontWeight: "bold" }}
                color="primary"
              >
                {errorLevel}
              </Typography>
            </div>
          )}
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            variant="contained"
            color={"primary"}
            sx={{ mr: 1 }}
            onClick={() => handleCorrectLater()}
          >
            Correct Errors Later
          </Button>
        </div>
      </div>

      <div style={{ margin: "20px 20px" }}>
        <Divider sx={{ backgroundColor: "black" }} />
        <Typography variant="headerMenu">
          The following errors have occured during validation
        </Typography>
      </div>
      <div style={{ margin: "20px 20px 40px 20px" }}>
        <TableContainer
          component={Card}
          sx={{
            // margin: "0 20px 40px 20px",
            borderRadius: "16px",
            boxShadow: "0px 4px 6px 0px rgba(62, 73, 84, 0.04)",
          }}
        >
          <Table>
            <TableBody>
              {errorData?.errors?.map((error, index) => (
                <React.Fragment key={index}>
                  <TableRow>
                    <TableCell style={{ width: "80%", padding: "15px 30px " }}>
                      <Typography style={{ color: "maroon" }}>
                        {`${error?.rule?.errorText} [Ref.# ${error?.rule?.id}]`}
                      </Typography>
                    </TableCell>
                    <TableCell style={{ padding: "15px 30px " }}>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          textDecoration: "none",
                          color: "inherit",
                          cursor: "pointer",
                        }}
                        onMouseOver={(e) => (e.target.style.color = "#003087")}
                        onMouseOut={(e) => (e.target.style.color = "inherit")}
                        onClick={() => correctionClicked(error)}
                      >
                        Correct now
                      </Typography>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default OpdeErrorListPage;
