import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  fetchPrintSurveyMenu,
  fetchSectionsBySurveyIdAndRespondenetId,
  fetchSurveyFiling,
  getGlossaryData,
} from "../Survey/Survey.slice";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { getTextFromHTML, htmlParser } from "../../Utility/constants";

const Glossary = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const surveyName = useSelector(
    (state) => state.survey.activeSurveys[0]?.name
  );
  const surveyRespondentId = useSelector(
    (state) => state.survey.respondentDetails?.id
  );
  const surveyFilingId = useSelector(
    (state) => state.survey.surveyFiling[0]?.id
  );
  const surveyMenu = useSelector((state) => state.survey.printSurveyMenu);
  const HospitalName = useSelector(
    (state) => state.survey.respondentDetails?.surveyOrganization?.name
  );
  const ahaId = useSelector(
    (state) => state.survey.respondentDetails?.surveyOrganization?.customId
  );
  const sectionsData = useSelector((state) => state?.survey?.sectionFilings);
  const glossaryData = useSelector((state) => state?.survey?.glossary);
  const [surveyToPrint, setSurveyToPrint] = useState(null);
  const [sectionSelection, setSectionSelection] = useState(null);
  const [sectionOptions, setSectionOptions] = useState([]);
  const [surveyId, setSurveyId] = useState(null);

  useEffect(() => {
    if (!surveyMenu.length) {
      console.log("the glo data is: ");
      dispatch(fetchPrintSurveyMenu({ surveyFilingId, surveyRespondentId }));
    }
  }, [dispatch]);

  useEffect(() => {
    console.log("the data for menu is: ", surveyMenu);
  }, [surveyMenu]);

  useEffect(() => {
    console.log("the glossary data is: ", glossaryData);
  }, [glossaryData]);

  useEffect(() => {
    let entireSurveyObj = {
      label: "Print Entire Glossary",
      value: "totalGlossary",
    };
    let options = [
      entireSurveyObj,
      ...sectionsData.map((item) => ({
        label: "Print Section " + item?.section?.name,
        value: item?.section?.id,
      })),
    ];
    console.log("the options are: ", options, sectionsData);
    setSectionOptions(options);
  }, [sectionsData]);

  const handleSectionSelection = async (event) => {
    setSectionSelection(event?.target?.value);
    // setSectionsLoading(true);
    if (event?.target?.value === "totalGlossary") {
      await dispatch(getGlossaryData({ surveyId: surveyId, sectionId: null }));
    } else if (event?.target?.value) {
      await dispatch(
        getGlossaryData({ surveyId: surveyId, sectionId: event?.target?.value })
      );
    }
  };

  //   const generatePdf = () => {
  //     console.log("-----", JSON.stringify(glossaryData))
  //     const jsonData = glossaryData;
  //     const doc = new jsPDF();
  //     for (const key in jsonData) {
  //         const element = jsonData[key]
  //         console.log(key, element.sectionName, element)
  //         console.log(element.items.length > 0 ? Object.keys(element.items[0]) : [])
  //         // doc.text(element.sectionName)
  //         key > 0 && doc.addPage();

  //         doc.text(`Section: ${element.sectionName}`, 20, 20);
  //         const columns = element.items.length > 0 ? Object.keys(element.items[0]) : []
  //         const rows = element.items.map((row) => Object.values(row));
  //         let columnWidths = { question: 40, helpText: 40 }
  //         doc.autoTable({
  //             // html: '#table',
  //             head: [columns],
  //             body: rows,
  //             startY: 30,
  //             columnStyles: {
  //                 question: { cellWidth: 100 },
  //                 helpText: { cellWidth: 100 },
  //             },
  //         });

  //     }

  //     doc.save('table.pdf');
  // };

  const generatePdf = () => {
    const doc = new jsPDF();

    doc.text(`${HospitalName} (${ahaId})`, 10, 5);

    doc.text(`${getTextFromHTML(glossaryData.title)}`, 10, 12);

    glossaryData.sections.forEach((section, index) => {
      if (index > 0) {
        doc.addPage();
      }

      doc.text(`Section: ${getTextFromHTML(section.sectionName)}`, 20, 20);

      const columns = ["Question", "Help Text"];
      const rows = section?.items?.map((item) => [
        item.question.replace(/(<([^>]+)>)/gi, ""),
        item.helpText.replace(/(<([^>]+)>)/gi, ""),
      ]);

      console.log("the rows are: ", rows);

      doc.autoTable({
        head: [columns],
        body: rows,
        startY: 30,
        columnStyles: {
          Question: { cellWidth: 100 },
          "Help Text": { cellWidth: 100 },
        },
      });
    });

    doc.save(`${getTextFromHTML(surveyName)} Glossary.pdf`);
  };

  const handleBack = () => {
    navigate(`/`, { state: { showFilingList: false } });
  };

  const handleSurveyToPrint = async (event) => {
    setSurveyId(event?.target?.value);
    if (event?.target?.value) {
      const response = await dispatch(
        fetchSurveyFiling({
          surveyId: event?.target?.value,
          surveyRespondentId,
        })
      );
      const payload = {
        surveyFilingId: response?.payload[0]?.id,
        surveyRespondentId: surveyRespondentId,
      };
      dispatch(fetchSectionsBySurveyIdAndRespondenetId(payload));
    }
    setSectionSelection(null);
  };

  return (
    <Grid
      sx={{
        "@media print": {
          marginLeft: "10px",
        },
      }}
    >
      <div style={{ marginBottom: "16px" }}>
        <Typography sx={{ fontSize: 25, fontWeight: "bold" }} color="primary">
          <b>{"Print Glossary"}</b>
        </Typography>
        <Typography color="primary">
          The
          <Typography display="inline" sx={{ fontWeight: "bold" }}>
            {" Glossary Printing "}
          </Typography>
          option provides a hardcopy of the help text associated with each
          question.
        </Typography>
      </div>
      <div style={{ marginBottom: "16px" }}>
        <Typography color="primary">
          Please select which glossary you would like and then click the Print
          button to download a PDF of the glossary.
        </Typography>
      </div>

      <div>
        <Typography sx={{ fontWeight: "bold" }} mb={1}>
          {"Select survey:"}
        </Typography>
        <FormControl size="small" sx={{ minWidth: "30%" }}>
          <Select
            id="SurveySelect"
            value={surveyId}
            placeholder="Please select survey"
            onChange={(event) => handleSurveyToPrint(event)}
          >
            {surveyMenu?.surveys?.map((opt) => (
              <MenuItem value={opt.id}>{getTextFromHTML(opt.name)}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      {surveyId && (
        <div my={2}>
          <Typography sx={{ fontWeight: "bold" }} mb={1} mt={3}>
            {"Select section:"}
          </Typography>
          <FormControl size="small" sx={{ minWidth: "30%" }}>
            <Select
              id="SurveySelect"
              value={sectionSelection}
              placeholder="Select section to print"
              onChange={(event) => handleSectionSelection(event)}
            >
              {sectionOptions.map((opt) => (
                <MenuItem value={opt.value}>
                  {getTextFromHTML(opt.label)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      )}
      <div
        style={{
          width: "100%",
          textAlign: "start",
          paddingleft: "15px",
          paddingTop: "10px",
        }}
      >
        <div className="inner-box inner-box2">
          {sectionSelection && glossaryData?.sections?.length && (
            <Button
              sx={{ mr: 1 }}
              variant="contained"
              color={"primary"}
              onClick={() => generatePdf()}
            >
              Print
            </Button>
          )}
          <Button
            sx={{ mr: 1 }}
            variant="contained"
            color={"primary"}
            onClick={() => handleBack()}
          >
            Back
          </Button>
        </div>
      </div>
    </Grid>
  );
};

export default Glossary;
