import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { CssBaseline } from "@mui/material";
import LoginLayout from "./components/Layouts/LoginLayout/LoginLayout";
import PublicLayout from "./components/Layouts/PublicLayout/PublicLayout";
import AppLayout from "./components/Layouts/AppLayout/AppLayout";
import LoginPage from "./pages/Auth/Login/LoginPage";
import { ThemeProvider } from "@mui/material/styles";
import { fetchRespondentDetailsByUsername } from "./pages/Survey/Survey.slice";
import theme from "./theme";
import { useDispatch } from "react-redux";
import NoSidebarLayout from "./components/Layouts/NoSidebarLayout/NoSidebarLayout";
import Glossary from "./pages/Reports/Glossary";
import PrintSurvey from "./pages/Reports/TotalSurvey";
import SubmitSurvey from "./pages/Survey/SubmitSurvey";
import Admin from "./pages/Admin";
import Help from "./pages/Help/Help";
import BuilderViewEdit from "./components/BuilderViewEditRouter/BuilderViewEdit";
import Opde from "./components/BuilderViewEditRouter/Opde";
import OpdeErrorListPage from "./components/BuilderViewEditRouter/OpdeErrorListPage";
import OpdeErrorCorrection from "./components/BuilderViewEditRouter/OpdeErrorCorrection";
const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const dispatch = useDispatch();
  // useEffect(() => {
  //   console.log(
  //     "the env variables are: ",u
  //     process.env.REACT_APP_BASE_URL,
  //     "............ ",
  //     process.env.REACT_APP_ENV
  //   );
  // }, []);
  useEffect(() => {
    console.log(
      "the env variables are: ",
      process.env,
      process.env.REACT_APP_BASE_URL,
      process.env.REACT_APP_ENV,
      "----- ",
      process.env.REACT_APP_REACT_APP_ENV
    );
  }, []);
  useEffect(() => {
    // dispatch(fetchRespondentDetailsByUsername(6731275));
  }, [dispatch]);

  const handleLogin = () => {
    // console.log(
    //   "the env variables are - login: ",
    //   process.env.REACT_APP_BASE_URL,
    //   "............ ",
    //   process.env.REACT_APP_ENV
    // );
    setIsLoggedIn(true);
  };

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <CssBaseline />
        <Routes>
          <Route
            path="/login"
            element={
              <LoginLayout>
                <LoginPage onLogin={handleLogin} />
              </LoginLayout>
            }
          />
          <Route
            path="/glossary"
            element={
              <PublicLayout>
                <NoSidebarLayout>
                  <Glossary />
                </NoSidebarLayout>
              </PublicLayout>
            }
          />
          <Route
            path="/print-survey"
            element={
              // <PublicLayout>
              <NoSidebarLayout>
                <PrintSurvey />
              </NoSidebarLayout>
              // </PublicLayout>
            }
          />
          <Route
            path="/submit-survey"
            element={
              <NoSidebarLayout>
                <SubmitSurvey />
              </NoSidebarLayout>
            }
          />
          <Route
            path="/admin"
            element={
              <NoSidebarLayout>
                <Admin />
              </NoSidebarLayout>
            }
          />
          <Route
            path="/help"
            element={
              <NoSidebarLayout>
                <Help />
              </NoSidebarLayout>
            }
          />
          <Route
            path="/view/:filingId"
            element={
              <NoSidebarLayout>
                <BuilderViewEdit page={"view"} />
              </NoSidebarLayout>
            }
          />
          <Route
            path="/edit/:filingId"
            element={
              <NoSidebarLayout>
                <BuilderViewEdit page={"edit"} />
              </NoSidebarLayout>
            }
          />
          <Route
            path="/opde"
            element={
              <NoSidebarLayout>
                <Opde />
              </NoSidebarLayout>
            }
          />
          <Route
            path="/opde/:filingId/:orgId"
            element={
              <NoSidebarLayout>
                <BuilderViewEdit page={"opde"} />
              </NoSidebarLayout>
            }
          />
          <Route
            path="/opde-error-list"
            element={
              <NoSidebarLayout>
                <OpdeErrorListPage />
              </NoSidebarLayout>
            }
          />
          <Route
            path="/opde-error-correction/:errorId"
            element={
              <NoSidebarLayout>
                <OpdeErrorCorrection />
              </NoSidebarLayout>
            }
          />
          <Route
            path="/*"
            element={
              <PublicLayout>
                <AppLayout />
              </PublicLayout>
            }
          />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
