import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "../components/Common/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
  gridClasses,
} from "@mui/x-data-grid";
import { randomId } from "@mui/x-data-grid-generator";
import TextField from "../components/Common/TextField";
import Checkbox from "../components/Common/Checkbox";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import {
  createSurveyHelper,
  getSectionAssignments,
  saveSurveySectionAssignments,
  getSurveyRespondents,
  deleteSurveyRespondents,
  updateSurveyRespondents,
} from "./Survey/Survey.service";
import { Divider, Typography } from "@mui/material";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { alpha, styled } from "@mui/material/styles";
import { getTextFromHTML } from "../Utility/constants";

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity
      ),
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
  [`& .${gridClasses.row}.odd`]: {
    "&:hover, &.Mui-hovered": {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
  },
}));

export default function HelperUsers() {
  const [rows, setRows] = useState([]);
  const [users, setUsers] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [dltId, setDltId] = useState(null);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");

  let sectionFilings = useSelector((state) => state?.survey?.sectionFilings);
  const supSectionFiling = useSelector(
    (state) => state?.survey?.supSectionFiling
  );

  if (supSectionFiling && supSectionFiling.length > 0) {
    sectionFilings = [...sectionFilings, ...supSectionFiling];
  }
  const allSections = sectionFilings?.map((sec) => sec.section);
  const [validationErrorModalOpen, setValidationErrorModalOpen] =
    useState(false);
  const [waitModalOpen, setWaitModalOpen] = useState(false);
  const surveyId = useSelector((state) => state?.survey?.activeSurveys[0]?.id);

  const [error, setError] = useState({});
  const respondentName = useSelector(
    (state) => state?.survey?.respondentDetails?.name
  );
  const respondentId = useSelector(
    (state) => state?.survey?.respondentDetails?.id
  );
  const organization = useSelector(
    (state) => state?.survey?.respondentDetails?.organization
  );
  const surveyOrganization = useSelector(
    (state) => state?.survey?.respondentDetails?.surveyOrganization
  );
  const surveyFilingId = useSelector(
    (state) => state?.survey?.surveyFilingDto?.id
  );
  const supFilingId = useSelector(
    (state) => state?.survey?.supplementalFiling?.id
  );
  const surveyRespondentId = useSelector(
    (state) => state?.survey?.respondentDetails?.id
  );
  const respondentDetails = useSelector(
    (state) => state.survey.respondentDetails
  );
  const allowHelperCreation = useSelector(
    (state) => state.survey?.respondentDetails?.canCreateHelpers
  );
  const surveyFiling = useSelector((state) => state?.survey?.surveyFilingDto);
  const supFiling = useSelector((state) => state.survey?.supplementalFiling);
  const fetchUsers = async () => {
    const userData = await getSurveyRespondents(surveyOrganization?.id);
    setUsers(userData?.data);
  };

  useEffect(() => {
    if (users?.length > 0) {
      const fetchSectionAssignments = async (FilingId, id) => {
        const payload = {
          surveyFilingId: FilingId,
          surveyRespondentId: id,
        };
        return await getSectionAssignments(payload).then((res) => res?.data);
      };

      const fetchData = async () => {
        const newRows = await Promise.all(
          users?.map(async (user) => {
            const RegSecs = await fetchSectionAssignments(
              surveyFilingId,
              user.id
            );
            let StateSecs = [];

            if (supFilingId) {
              StateSecs = await fetchSectionAssignments(supFilingId, user.id);
            }

            const assignedSections = [...RegSecs, ...StateSecs];

            const initialRow = {
              id: user.id,
              firstName: user.firstName,
              lastName: user.lastName,
              email: user.email,
              canCreateHelpers: user.canCreateHelpers,
              canSubmitSurvey: user.canSubmitSurvey,
              assignedSections: assignedSections,
              ...user,
            };

            assignedSections?.forEach((survey) => {
              const matchingSection = allSections?.find(
                (section) => section.id === survey.sectionId
              );
              if (matchingSection) {
                initialRow[matchingSection?.id] = true;
              } else {
                initialRow[matchingSection?.id] = false;
              }
            });

            return initialRow;
          })
        );

        setRows(newRows);

        if (newRows?.length > 0) {
          const firstRowId = newRows[0].id;
          setRowModesModel({
            [firstRowId]: { mode: GridRowModes.View, fieldToFocus: "name" },
          });
        }
      };

      fetchData();
    } else {
      fetchUsers();
    }
  }, [users]);

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email Address is required"),
  });

  const validateFormData = async (formData) => {
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      return null;
    } catch (validationErrors) {
      const newErrors = {};
      validationErrors.inner?.forEach((error) => {
        newErrors[error.path] = error.message;
      });
      return newErrors;
    }
  };

  const handleSaveClick = (id) => async () => {
    const savedRow = rows?.find((row) => row.id === id);

    const {
      firstName,
      lastName,
      email,
      canCreateHelpers,
      canSubmitSurvey,
      ...rest
    } = savedRow;

    const selectedSections = [];

    allSections?.forEach((section) => {
      if (savedRow[section.id]) {
        selectedSections.push(section.id);
      }
    });
    const newRecord = savedRow.hasOwnProperty("isNew");

    const validationErrors = await validateFormData({
      firstName,
      lastName,
      email,
    });
    const handleError = (validationErrors) => {
      setError((prevErrors) => ({ ...prevErrors, [id]: validationErrors }));
      setValidationErrorModalOpen(true);
    };

    const handleSuccess = () => {
      setSuccessSnackbarOpen(true);
      fetchUsers();
    };
    if (newRecord) {
      if (!validationErrors) {
        setError((prevErrors) => ({ ...prevErrors, [id]: {} }));

        const additionalPayloads = {
          id: null,
          organization: organization,
          surveyOrganization: surveyOrganization,
          username: email,
          canSubmitSurvey: canSubmitSurvey,
          canCreateHelpers: canCreateHelpers,
          createdBy: respondentName,
        };
        setWaitModalOpen(true);
        const response = await createSurveyHelper({
          surveyRespondent: {
            firstName,
            lastName,
            email,
            ...additionalPayloads,
          },
          surveyFiling: surveyFiling,
          ...(supFiling &&
            Object.keys(supFiling).length > 0 && {
              supplementalFiling: supFiling,
            }),
        });

        const surveyRespondentId = response?.data?.id;
        const selectedRegSec = [];
        const selectedStateSec = [];

        selectedSections.forEach((sectionId) => {
          const foundSection = supSectionFiling.find(
            (filing) => filing.sectionId === sectionId
          );

          if (foundSection) {
            selectedStateSec.push(sectionId);
          } else {
            selectedRegSec.push(sectionId);
          }
        });

        if (surveyFilingId && surveyRespondentId && respondentId) {
          await saveSurveySectionAssignments({
            surveyFilingId: surveyFilingId,
            surveyRespondentId: surveyRespondentId,
            assignerSurveyRespondentId: respondentId,
            sectionIdList: selectedRegSec,
          });
        }
        if (supFilingId && surveyRespondentId && respondentId) {
          await saveSurveySectionAssignments({
            surveyFilingId: supFilingId,
            surveyRespondentId: surveyRespondentId,
            assignerSurveyRespondentId: respondentId,
            sectionIdList: selectedStateSec,
          });
        }

        setRowModesModel({
          ...rowModesModel,
          [id]: { mode: GridRowModes.View },
        });

        if (response?.status === 200) {
          setWaitModalOpen(false);
          setAlertSeverity("success");
          setAlertMsg("Helper User is created successfully.");
        } else if (response?.response?.status === 400) {
          setWaitModalOpen(false);
          setAlertSeverity("error");
          setAlertMsg(response?.response?.data?.detail);
        } else {
          setWaitModalOpen(false);
          setAlertSeverity("error");
          setAlertMsg("An error occurred while creating the Helper User.");
        }
        handleSuccess();
      } else {
        handleError(validationErrors);
      }
    } else {
      if (!validationErrors) {
        setError((prevErrors) => ({ ...prevErrors, [id]: {} }));
        const payload = users.find((user) => user?.id === savedRow?.id);
        const response = await updateSurveyRespondents({
          ...payload,
          firstName,
          lastName,
          email,
          username: email,
          canSubmitSurvey,
          canCreateHelpers,
        });

        const sectionIdList = Object.entries(savedRow)
          .filter(
            ([key, value]) =>
              typeof key === "string" && /^\d+$/.test(key) && value === true
          )
          .map(([key]) => parseInt(key));
        const selectedStateSec = [];
        const selectedRegSec = [];

        selectedSections.forEach((sectionId) => {
          const foundSection = supSectionFiling.find(
            (filing) => filing.sectionId === sectionId
          );

          if (foundSection) {
            selectedStateSec.push(sectionId);
          } else {
            selectedRegSec.push(sectionId);
          }
        });
        if (
          surveyFilingId &&
          surveyRespondentId &&
          respondentId &&
          response?.status === 200
        ) {
          await saveSurveySectionAssignments({
            surveyFilingId: surveyFilingId,
            surveyRespondentId: savedRow.id,
            assignerSurveyRespondentId: respondentId,
            sectionIdList: selectedRegSec,
          });
        }

        if (supFilingId && respondentId && response?.status === 200) {
          await saveSurveySectionAssignments({
            surveyFilingId: supFilingId,
            surveyRespondentId: savedRow.id,
            assignerSurveyRespondentId: respondentId,
            sectionIdList: selectedStateSec,
          });
        }

        setRowModesModel({
          ...rowModesModel,
          [id]: { mode: GridRowModes.View },
        });

        if (response?.status === 200) {
          setAlertSeverity("success");
          setAlertMsg("Helper User is Updated successfully.");
        } else if (response?.response?.status === 400) {
          setAlertSeverity("error");
          setAlertMsg(response?.response?.data?.detail);
        } else {
          setAlertSeverity("error");
          setAlertMsg("An error occurred while updating the Helper User.");
        }
        handleSuccess();
      } else {
        handleError(validationErrors);
      }
    }
  };

  const handleDeleteClick = (helperId) => () => {
    setDltId(helperId);
    setConfirmDialogOpen(true);
  };

  const confirmDelete = async () => {
    setRows(rows.filter((row) => row.id !== dltId));
    if (dltId && surveyRespondentId) {
      const response = await deleteSurveyRespondents({
        surveyRespondentId: dltId,
        requestorSurveyRespondentId: surveyRespondentId,
      });
      if (response?.status === 200) {
        fetchUsers();
        setConfirmDialogOpen(false);
        setDltId(null);
        setAlertMsg("Helper User is deleted successfully.");
        setSuccessSnackbarOpen(true);
        setAlertSeverity("success");
      } else {
        fetchUsers();
        setConfirmDialogOpen(false);
        setDltId(null);
        setAlertMsg("An error occurred while deleting the Helper User.");
        setSuccessSnackbarOpen(true);
        setAlertSeverity("error");
      }
    }
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows?.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleInputChange = (value, field, id) => {
    setRows((prevRows) => {
      const updatedRows = prevRows?.map((row) => {
        if (row.id === id) {
          return { ...row, [field]: value };
        }
        return row;
      });

      return updatedRows;
    });
  };

  const handleCheckboxChange = (event, field, id) => {
    const { checked } = event.target;

    setRows((prevRows) => {
      const updatedRows = prevRows?.map((row) => {
        if (row.id === id) {
          return { ...row, [field]: checked };
        }
        return row;
      });

      const AllSectionsChecked = !allSections.some(
        (section) => !updatedRows.find((row) => row.id === id)[section.id]
      );

      if (!AllSectionsChecked) {
        updatedRows.find((row) => row.id === id)["canSubmitSurvey"] = false;
      }

      return updatedRows;
    });
  };

  const columns = [
    {
      field: "firstName",
      headerName: "First Name*",
      width: 150,
      headerAlign: "center",
      type: "actions",
      cellClassName: "actions",
      getActions: (params) => {
        const isInEditMode =
          rowModesModel[params.id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <>
              <TextField
                height="small"
                name="firstName"
                value={params.row.firstName}
                onChange={(val) =>
                  handleInputChange(val, "firstName", params.id)
                }
                error={error[params.id]?.firstName}
              />
            </>,
          ];
        }

        return [<>{params.row.firstName}</>];
      },
    },
    {
      field: "lastName",
      headerName: "Last Name*",
      width: 150,
      headerAlign: "center",
      type: "actions",
      cellClassName: "actions",
      getActions: (params) => {
        const isInEditMode =
          rowModesModel[params.id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <TextField
              helperUserOnChange={true}
              height="small"
              id={`lastName-${params.id}`}
              name="lastName"
              value={params.row.lastName}
              onChange={(val) => handleInputChange(val, "lastName", params.id)}
              error={error[params.id]?.lastName}
            />,
          ];
        }

        return [<>{params.row.lastName}</>];
      },
    },
    {
      field: "email",
      headerName: "Email Address*",
      width: 250,
      headerAlign: "center",
      type: "actions",
      cellClassName: "actions",
      getActions: (params) => {
        const isInEditMode =
          rowModesModel[params.id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <TextField
              height="small"
              id={`email-${params.id}`}
              name="email"
              value={params.row.email}
              onChange={(val) => handleInputChange(val, "email", params.id)}
              error={error[params.id]?.email}
            />,
          ];
        }

        return [<>{params.row.email}</>];
      },
    },
    ...(allSections || []).map((section) => ({
      field: section.name,
      headerName: getTextFromHTML(section.name),
      width: 150,
      headerAlign: "center",
      type: "actions",
      cellClassName: "actions",
      getActions: (params) => {
        const isInEditMode =
          rowModesModel[params.id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <Checkbox
              onChange={(event) =>
                handleCheckboxChange(event, section.id, params.id)
              }
              checked={params.row[section.id]}
            />,
          ];
        }

        return [
          params.row[section.id] ? <CheckBoxIcon color="success" /> : <></>,
        ];
      },
    })),
    {
      field: "canCreateHelpers",
      headerName: "Allow this staff to create other users? (Y/N)",
      width: 150,
      headerAlign: "center",
      type: "actions",
      cellClassName: "actions",
      getActions: (params) => {
        const isInEditMode =
          rowModesModel[params.id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <Checkbox
              checked={params.row.canCreateHelpers}
              onChange={(event) =>
                handleCheckboxChange(event, "canCreateHelpers", params.id)
              }
            />,
          ];
        }

        return [
          params.row["canCreateHelpers"] ? (
            <CheckBoxIcon color="success" />
          ) : (
            <></>
          ),
        ];
      },
    },
    {
      field: "canSubmitSurvey",
      headerName: "Allow this staff to submit the whole survey? (Y/N)",
      width: 150,
      headerAlign: "center",
      type: "actions",
      cellClassName: "actions",
      getActions: (params) => {
        const isInEditMode =
          rowModesModel[params.id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          const allSectionsChecked = allSections?.every(
            (section) => params.row[section.id]
          );
          return [
            <Checkbox
              checked={params.row.canSubmitSurvey}
              disabled={!allSectionsChecked}
              onChange={(event) =>
                handleCheckboxChange(event, "canSubmitSurvey", params.id)
              }
            />,
          ];
        }

        return [
          params.row["canSubmitSurvey"] ? (
            <CheckBoxIcon color="success" />
          ) : (
            <></>
          ),
        ];
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 200,
      cellClassName: "actions",
      getActions: (params) => {
        const isInEditMode =
          rowModesModel[params?.id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(params?.id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(params?.id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            disabled={!respondentDetails?.canCreateHelpers}
            className="textPrimary"
            onClick={handleEditClick(params?.id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            disabled={!respondentDetails?.canCreateHelpers}
            onClick={handleDeleteClick(params?.id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  function EditToolbar(props) {
    const { setRows, setRowModesModel } = props;

    const handleClick = () => {
      const id = randomId();

      const initialRow = {
        id,
        firstName: "",
        lastName: "",
        email: "",
        canCreateHelpers: false,
        canSubmitSurvey: false,
        isNew: true,
      };

      allSections?.forEach((section) => {
        initialRow[section.id] = true;
      });

      setRows((oldRows) => [initialRow, ...oldRows]);

      setRowModesModel((oldModel) => ({
        ...oldModel,
        [id]: { mode: GridRowModes.Edit, fieldToFocus: "name" },
      }));
    };

    return (
      <GridToolbarContainer>
        {allowHelperCreation && (
          <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
            Add Staff
          </Button>
        )}
      </GridToolbarContainer>
    );
  }

  return (
    <Box>
      <Typography sx={{ fontSize: 20, fontWeight: "bold" }} color="primary">
        New Survey Response Staff
      </Typography>
      <Divider sx={{ backgroundColor: "black" }} />
      <Typography sx={{ marginBottom: "20px" }}>
        Please list all survey response staff or users that you would like to
        add below, indicate the sections you would like each one be able to
        input responses to, as well as whether you would like to allow each
        staff to create other users and/or be able to submit the whole survey.
        (Note that only users allowed access to all sections can be allowed to
        submit the survey.)
      </Typography>
      <Box
        sx={{
          height: 400,
          marginBottom: "20px",
        }}
      >
        <StripedDataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: { paginationModel: { pageSize: 25 } },
          }}
          pageSizeOptions={[5, 10, 25]}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          slots={{
            toolbar: EditToolbar,
          }}
          slotProps={{
            toolbar: { setRows, setRowModesModel },
          }}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
          }
          sx={{
            "& .MuiDataGrid-columnHeaderTitle": {
              whiteSpace: "break-spaces",
              lineHeight: 1,
              paddingY: 3,
            },
            "& .MuiDataGrid-columnHeader": {
              height: "unset !important",
            },
            "& .MuiDataGrid-columnHeaders": {
              maxHeight: "168px !important",
            },
            width: "84vw",
            margin: "0 auto",
          }}
        />
      </Box>
      <Dialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
      >
        <DialogContent>Do you want to Delete the Helper User ?</DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <Button onClick={() => setConfirmDialogOpen(false)}>Cancel</Button>
          <Button onClick={confirmDelete} color="error">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={validationErrorModalOpen}
        onClose={() => setValidationErrorModalOpen(false)}
      >
        <DialogContent>Please fill in all required fields.</DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <Button onClick={() => setValidationErrorModalOpen(false)}>OK</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={waitModalOpen} onClose={() => setWaitModalOpen(false)}>
        <DialogContent>Please wait...</DialogContent>
      </Dialog>

      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={4000}
        onClose={() => setSuccessSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setSuccessSnackbarOpen(false)}
          severity={alertSeverity}
        >
          {alertMsg}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
}
