import React, { useEffect, useState } from "react";
import PageRoutes from "../../../Routes/Routes";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../../Footer/Footer";
import Navbar from "../../Navbar/Navbar";
import Sidebar from "../../Sidebar/Sidebar";
import "../Layout.scss";
import { Route, Routes, useLocation } from "react-router-dom";
import PrintSurvey from "../../../pages/Reports/TotalSurvey";
import Glossary from "../../../pages/Reports/Glossary";
import { Grid } from "@mui/material";

const NoSidebarLayout = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = React.useState(true);
  let sectionFilings = useSelector((state) => state.survey?.sectionFilings);
  const supSectionFiling = useSelector(
    (state) => state?.survey?.supSectionFiling
  );

  if (supSectionFiling && supSectionFiling.length > 0) {
    sectionFilings = [...sectionFilings, ...supSectionFiling];
  }
  const location = useLocation();

  const toggleSidebar = (open) => {
    setSidebarOpen(!open);
  };

  return (
    <div className="app-layout">
      <Navbar toggleSidebar={toggleSidebar} />
      <div className="main-layout">
        <main style={{ width: "100%", overflow: "auto" }}>
          <Grid mx={5}>
            {children}
          </Grid>
        </main>
      </div>
      <Footer />
    </div>
  );
};

export default NoSidebarLayout;