import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import {
  // fetchActiveSurveysByRespondentId,
  getBuilderUserData,
  setDisableSurvey,
  setRespondantDetails,
  setIsOpde,
  setActiveSurvey,
  fetchAllPagesBySurveyId,
  setUpdateStatuses,
  setDisableAdmin,
} from "../../pages/Survey/Survey.slice";

export default function BuilderViewEdit({ page }) {
  let dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { filingId, orgId } = useParams();
  const [showLoading, setShowLoading] = useState(true);

  const searchParams = new URLSearchParams(location.search);
  const updateStatuses = searchParams.get("updateStatuses");
  const isGroupUser = searchParams.get("isGroupUser");

  useEffect(() => {
    if (isGroupUser === "true") {
      dispatch(setDisableAdmin(true));
    }
    dispatch(getBuilderUserData(filingId)).then((response) => {
      const activeSurvey =
        response?.payload?.survey !== null
          ? response?.payload?.survey
          : response?.payload?.activeSurveys?.[0];
      if (typeof activeSurvey === "object" && "id" in activeSurvey) {
        dispatch(setActiveSurvey([activeSurvey]));
        dispatch(fetchAllPagesBySurveyId(activeSurvey?.id));
      }
      if (response?.payload?.surveyFiling) {
        if (updateStatuses === "false") {
          dispatch(setUpdateStatuses(false));
        }
        dispatch(setRespondantDetails(response?.payload?.surveyRespondent));
        if (page === "view") {
          dispatch(setDisableSurvey(true));
        }
        setShowLoading(false);

        if (page === "opde") {
          dispatch(setIsOpde(true));
          navigate(`/opde`, {
            state: { surveyFilingId: filingId, builderOrgId: orgId },
          });
        } else {
          console.log("to /");
          navigate(`/`, {
            state: { showFilingList: false, fromBuilder: true },
          });
        }
      }
    });
  }, []);

  return showLoading ? (
    <>
      <Box
        display="flex"
        color="primary"
        sx={{ height: "100vh", width: "100%", fontSize: "24px" }}
        alignItems="center"
        justifyContent="center"
      >
        One moment please...
      </Box>
    </>
  ) : (
    <></>
  );
}
