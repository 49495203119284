import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  Grid,
  StyledEngineProvider,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import HelpTextPopper from "../../components/Common/Popper";
import TextArea from "../../components/Common/TextArea";
import RenderCheckboxGrp from "../../components/QuestionTypes/RenderCheckboxGrp";
import RenderCommonGrp from "../../components/QuestionTypes/RenderCommonGrp";
import RenderQuestion from "../../components/QuestionTypes/RenderQuestion";
import RenderRadioGrp from "../../components/QuestionTypes/RenderRadioGrp";
import { htmlParser } from "../../Utility/constants";
import {
  correctionQuesSave,
  // fetchActiveSurveysByRespondentId,
  getCorrectionQes,
} from "../../pages/Survey/Survey.slice";

const OpdeErrorCorrection = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const error = location?.state?.errorText;
  const { errorId } = useParams();
  const HospitalName = useSelector(
    (state) => state.survey.respondentDetails?.surveyOrganization?.name
  );
  const disableSurvey = useSelector((state) => state.survey.disableSurvey);
  const surveyRespondentId = useSelector(
    (state) => state.survey.respondentDetails?.id
  );
  const errorPageLoading = useSelector(
    (state) => state.survey?.errorPageLoading
  );
  const selectedSectionId = useSelector(
    // change to take from payload
    (state) => state.survey.currentSelection.sectionId
  );
  let sectionFilings = useSelector((state) => state.survey.sectionFilings);
  const supSectionFiling = useSelector(
    (state) => state?.survey?.supSectionFiling
  );

  if (supSectionFiling && supSectionFiling.length > 0) {
    sectionFilings = [...sectionFilings, ...supSectionFiling];
  }
  const selectedSectionFilingId = sectionFilings.find(
    (obj) => obj.sectionId === selectedSectionId
  )?.id;
  const pagePayload = useSelector((state) => state.survey.pagePayload);
  const userID = useSelector((state) => state.survey.respondentDetails?.id);
  const surveyQes = useSelector(
    (state) => state.survey.correctionPageDetails?.questionGroupDTOs
  );
  const fieldIds = useSelector(
    (state) => state.survey.correctionPageDetails?.fieldIds
  );
  const reqAction = useSelector(
    (state) => state.survey.correctionPageDetails?.requiredAction
  );

  const supFilingId = useSelector(
    (state) => state.survey?.supplementalFiling?.id
  );
  const supFiling = useSelector((state) => state.survey?.supSectionFiling);
  const errorPageDetails = useSelector(
    (state) => state.survey?.errorPageDetails
  );

  const surveyFilingId = useSelector(
    (state) => state.survey.surveyFilingDto?.id
  );
  const builderOrgId = useSelector((state) => state.survey?.builderOrgId);

  const updateStatuses = useSelector((state) => state.survey.updateStatuses);

  const isSupError = supFiling.some(
    (fil) => fil.id === errorPageDetails?.sectionFilingId
  );

  const correctionObj = { page: "correction", fieldIds: fieldIds };
  const [checked, setChecked] = React.useState(false);
  const [confirmCmts, setConfirmCmts] = useState(null);
  const [errorPayload, setErrorPayload] = useState({
    answers: [],
    mainSurveyFilingId: pagePayload.surveyFilingId,
    surveyFilingId: isSupError ? supFilingId : pagePayload.surveyFilingId,
    pageFilingId: pagePayload.pageFilingId,
    sectionFilingId: selectedSectionFilingId,
    runValidations: false,
    modifiedBy: "string",
    loopIndex: 1,
    survErrorId: Number(errorId),
    memo: null,
    confirm: null,
    surveyRespondentId: pagePayload.surveyRespondentId,
    isOnePageDataEntry: true,
    isDataEntryClerk: false,
    isBatch: false,
    updateStatuses,
  });

  const ansObj = {
    id: null,
    value: null,
    loopIndex: 1,
    fieldId: null,
    surveyFilingId: pagePayload.surveyFilingId,
    surveyRespondentId: pagePayload.surveyRespondentId,
    createdBy: "string",
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
    let payload = { ...errorPayload };
    payload.confirm = event?.target?.checked ? "yes" : null;
    setErrorPayload(payload);
  };

  // useEffect(() => {
  //   if (userID) {
  //     dispatch(fetchActiveSurveysByRespondentId(userID));
  //   }
  // }, [dispatch, userID]);

  useEffect(() => {
    if (errorId) {
      dispatch(getCorrectionQes(errorId));
    }
  }, [errorId]);

  const handleBack = () => {
    navigate(`/opde-error-list`);
  };

  const prepPayloadForCorrection = (value, field) => {
    let payload = { ...errorPayload };
    let index = payload.answers.findIndex((obj) => obj.fieldId === field?.id);
    if (index !== -1) {
      let ans = { ...ansObj };
      ans.value = value;
      ans.id = field?.answer?.id ? field?.answer?.id : null;
      ans.fieldId = field?.id;
      payload.answers[index] = ans;
    } else {
      let ans = { ...ansObj };
      ans.value = value;
      ans.id = field?.answer?.id ? field?.answer?.id : null;
      ans.fieldId = field?.id;

      payload.answers.push(ans);
    }
    setErrorPayload(payload);
  };

  const saveAndContinue = () => {
    dispatch(correctionQuesSave(errorPayload)).then((data) => {
      if (data?.payload?.gotoNext === "complete") {
        navigate("/submit-survey");
      } else if (
        data?.payload?.gotoNext === "onePageDataEntry" ||
        data?.payload?.gotoNext === "home"
      ) {
        navigate(`/opde/${surveyFilingId}/${builderOrgId}`);
      } else if (data?.payload?.gotoNext === "errors") {
        navigate("/opde-error-list");
      }
    });
  };

  const confirmCommentsChanged = (event) => {
    let payload = { ...errorPayload };
    payload.memo = event?.target?.value;
    setConfirmCmts(event?.target?.value);
    setErrorPayload(payload);
  };

  return errorPageLoading ? (
    <>
      <Box
        display="flex"
        sx={{ height: "100vh", width: "100%" }}
        bgcolor="#d9e0ed"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress />
      </Box>
    </>
  ) : (
    <>
      <div className="container">
        <div className="box box1">
          <div className="title">
            <Typography variant="headerMenu" color="primary">
              {`${HospitalName} (${surveyRespondentId})`}
            </Typography>
          </div>
          <Typography>
            {"The following error has occured during validation"}
          </Typography>
          <Typography color="maroon">{`${error?.errorText} [Ref.# ${error?.id}]`}</Typography>
          <Typography mt={2}>
            {"Please correct the answer(s), indicated by "}
            <span style={{ color: "maroon" }}>{"<<"}</span>
            {", to the following question(s)."}
          </Typography>
          {reqAction === "Confirm" && (
            <Typography>
              <b>{"OR "}</b>
              {"confirm/explain why these values were correct."}
            </Typography>
          )}
        </div>
      </div>
      <form>
        {surveyQes &&
          surveyQes.map((qes) => (
            <>
              {qes.groupType.name === "radiogrp" && (
                <RenderRadioGrp
                  getCorrectedData={prepPayloadForCorrection}
                  correctionObj={correctionObj}
                  questionsGrp={qes}
                  type={disableSurvey ? "preview" : ""}
                  hideComment={true}
                />
              )}
              {qes.groupType.name === "checkboxgrp" && (
                <RenderCheckboxGrp
                  getCorrectedData={prepPayloadForCorrection}
                  correctionObj={correctionObj}
                  questionsGrp={qes}
                  type={disableSurvey ? "preview" : ""}
                  hideComment={true}
                />
              )}
              {qes.groupType.name === "commongrp" && (
                <RenderCommonGrp
                  getCorrectedData={prepPayloadForCorrection}
                  correctionObj={correctionObj}
                  questionsGrp={qes}
                  type={disableSurvey ? "preview" : ""}
                  hideComment={true}
                />
              )}
              {qes.groupType.name !== "radiogrp" &&
                qes.groupType.name !== "checkboxgrp" &&
                qes.groupType.name !== "commongrp" &&
                qes.questions &&
                qes.questions.length > 0 &&
                qes.questions.length === 1 &&
                qes.questions.map((question) => {
                  return (
                    <RenderQuestion
                      correctionObj={correctionObj}
                      getCorrectedData={prepPayloadForCorrection}
                      question={question}
                      multiple={false}
                      type={disableSurvey ? "preview" : ""}
                      hideComment={true}
                    />
                  );
                })}
              {qes.groupType.name !== "radiogrp" &&
                qes.groupType.name !== "checkboxgrp" &&
                qes.groupType.name !== "commongrp" &&
                qes.questions?.length > 1 && (
                  <>
                    <StyledEngineProvider injectFirst>
                      <Card className="card">
                        <CardContent className="card-header">
                          <Grid container direction={"column"}>
                            <Grid
                              item
                              alignSelf="start"
                              className="question-container"
                            >
                              <Typography variant="question">
                                {htmlParser(
                                  qes.descriptionPosition !== null ||
                                    qes.descriptionPosition !== "No Title"
                                    ? (qes.label || "") + " " + (qes.name || "")
                                    : (qes.label || "") +
                                        " " +
                                        (qes.description || "")
                                )}
                                {qes?.helpText && (
                                  <HelpTextPopper
                                    question={qes}
                                  ></HelpTextPopper>
                                )}
                              </Typography>
                            </Grid>
                          </Grid>
                          <div>
                            <Typography variant="h6">
                              {htmlParser(
                                qes.descriptionPosition !== null ||
                                  qes.descriptionPosition !== "No Title"
                                  ? qes.description || ""
                                  : ""
                              )}
                            </Typography>
                          </div>
                        </CardContent>
                        <CardContent
                          className="card-content"
                          sx={{ backgroundColor: "#fff !important" }}
                        >
                          {qes.questions.map((question) => {
                            return (
                              <RenderQuestion
                                correctionObj={correctionObj}
                                getCorrectedData={prepPayloadForCorrection}
                                question={question}
                                multiple={true}
                                type={disableSurvey ? "preview" : ""}
                                hideComment={true}
                              />
                            );
                          })}
                        </CardContent>
                      </Card>
                    </StyledEngineProvider>
                  </>
                )}
            </>
          ))}

        <Grid ml={2}>
          {reqAction === "Confirm" && (
            <Grid>
              <Grid mt={2} sx={{ display: "flex", alignItems: "center" }}>
                <Typography mr={1}>
                  {"If you have "}
                  <b>{"NOT "}</b>
                  {"made any changes to the answers above, please "}
                  <b>{"confirm "}</b>
                  {"if your answers were correct here"}
                </Typography>
                <Checkbox
                  checked={checked}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              </Grid>
              <Grid>
                <Typography>
                  <b>{"OR "}</b>
                  {"give a brief explanation of the data entered:"}
                </Typography>
              </Grid>
              <Grid mb={2}>
                <TextArea
                  // value={fieldConfig?.value}
                  minRows={5}
                  maxLength={4000}
                  size={500}
                  onBlur={(event) => confirmCommentsChanged(event)}
                />
              </Grid>
            </Grid>
          )}

          <div style={{ width: "100%", textAlign: "start" }}>
            <div
              className="inner-box inner-box2"
              style={{ justifyContent: "start !important" }}
            >
              <Button
                sx={{ mr: 1 }}
                variant="contained"
                color={"primary"}
                onClick={() => handleBack()}
              >
                Back to Error List
              </Button>
              <Button
                sx={{ mr: 1 }}
                variant="contained"
                color={"primary"}
                onClick={() => saveAndContinue()}
              >
                Save
              </Button>
            </div>
          </div>
        </Grid>
      </form>
    </>
  );
};

export default OpdeErrorCorrection;
