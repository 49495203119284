import { Button, Grid, Typography } from "@mui/material";
import React, { useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import myPdf from '../../assets/Walkthrough.pdf';

import './Help.scss';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

// pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//   'pdfjs-dist/build/pdf.worker.min.js',
//   import.meta.url,
// ).toString();

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export default function Help() {

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    console.log('doc loaded successfully')
    setNumPages(numPages);
  }

  const downloadWalkthrough = () => {
    console.log('pdf clicked');
    const link = document.createElement('a');
    link.href = myPdf;
    link.download = 'your-file-name.pdf';
    link.click();
  };


  return (
    <>
      <Grid style={{width:"100%"}}>
        <Typography>Welcome to the Improved AHA Annual Survey Online System. We are very proud to be able to bring
          you this interactive, intelligent, and user-friendly application that will guide you through completing the
          AHA Annual Survey. We hope you will find the online survey easy to use, helpful and informative. If
          at any time you have questions, please do not hesitate to contact us at 1-800-530-9092 or via e-mail at 
          <span>{" "}<a href="mailto:surveysupport@aha.org">surveysupport@aha.org</a></span>
        </Typography>
        {/* <Document
          file={myPdf}
          onLoadSuccess={onDocumentLoadSuccess}
          style={{width:"100%"}}
        >
          <Page size='A4' />
        </Document> */}
        <div style={{ width: "100%", textAlign: "start", paddingleft: "15px", paddingTop: "10px", paddingBottom: "10px" }}>
          <div className="inner-box inner-box2">
            <Button
              sx={{ mr: 1 }}
              variant="contained"
              color={"primary"}
              onClick={() => downloadWalkthrough()}
            >
              Download Walkthrough
            </Button>
          </div>
        </div>
        <div style={{width:"100%"}}>
          <Document file={myPdf} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                pageNumber={index + 1}
              />
            ))}
          </Document>
        </div>
      </Grid>
    </>
  );
}