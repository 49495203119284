import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import {
  // fetchActiveSurveysByRespondentId,
  fetchNextPage,
  fetchPageDataOfLastYear,
  fetchPreviousPage,
  fetchQuestionsInPage,
  setDataIds,
  validateSurvey,
  setShowErrorPage,
  setShowLanding,
  getSurveyFiling,
  fetchSectionsBySurveyIdAndRespondenetId,
} from "./Survey.slice";
import {
  Box,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Popper,
  StyledEngineProvider,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import "../../components/QuestionTypes/QuestionTypes.scss";
import RenderQuestion from "../../components/QuestionTypes/RenderQuestion";
import RenderRadioGrp from "../../components/QuestionTypes/RenderRadioGrp";
import RenderCheckboxGrp from "../../components/QuestionTypes/RenderCheckboxGrp";
import parse from "html-react-parser";
import { htmlParser } from "../../Utility/constants";
import HelpTextPopper from "../../components/Common/Popper";
import Divider from "@mui/material/Divider";
import "./SurveyForm.scss";
import ProgressBar from "../../components/Common/ProgressBar";
import RenderCommonGrp from "../../components/QuestionTypes/RenderCommonGrp";
import { Dayjs } from "dayjs";
import ErrorListPage from "../Error-Correction/ErrorListPage";
import { showPageFilingHistoryLink } from "./Survey.service";

const SurveyForm = () => {
  const dispatch = useDispatch();
  const userID = useSelector((state) => state.survey.respondentDetails?.id);
  const disableSurvey = useSelector((state) => state.survey.disableSurvey);
  const selectedsectionId = useSelector(
    (state) => state.survey?.currentSelection?.sectionId
  );
  const selectedPageId = useSelector(
    (state) => state.survey?.currentSelection?.pageId
  );

  const surveyFilingId = useSelector(
    (state) => state.survey.surveyFiling[0]?.id
  );
  const supFilingId = useSelector(
    (state) => state?.survey?.supplementalFiling?.id
  );
  const surveyRespondentId = useSelector(
    (state) => state.survey.respondentDetails?.id
  );

  let sectionFilings = useSelector((state) => state.survey?.sectionFilings);
  const supSectionFiling = useSelector(
    (state) => state?.survey?.supSectionFiling
  );
  if (supSectionFiling && supSectionFiling.length > 0) {
    sectionFilings = [...sectionFilings, ...supSectionFiling];
  }
  const SectionData = sectionFilings.find(
    (obj) => obj?.section?.id === selectedsectionId
  );

  const historicalFilingAvailable = useSelector(
    (state) => state.survey.historicalFiling
  );
  let allPages = useSelector((state) => state.survey.allPages);
  const supPages = useSelector((state) => state?.survey?.supPages);
  if (supPages && supPages.length > 0) {
    allPages = [...allPages, ...supPages];
  }

  const PageData = allPages.find((obj) => obj.id === selectedPageId);

  const questionsLoading = useSelector((state) => state.survey.pageLoading);
  const questionsInPage = useSelector(
    (state) => state?.survey?.questionsInPage?.questionGroupDTOs
  );
  const lastUpdatedDate = useSelector(
    (state) => state.survey?.questionsInPage?.surveyFilingLastModified
  );
  const openedQuestions = useSelector((state) => state.survey.openedQuestions);
  const pagePayload = useSelector((state) => state.survey.pagePayload);
  const selectedSectionId = useSelector(
    (state) => state.survey?.currentSelection?.sectionId
  );

  const selectedSectionFilingId = sectionFilings.find(
    (obj) => obj.sectionId === selectedSectionId
  )?.id;
  const surveyCompletedPercentage = useSelector(
    (state) => state.survey.surveyFilingDto?.percentPagesCompleted
  );
  const errorData = useSelector((state) => state.survey?.errorPageDetails);

  const errorPageLoading = useSelector(
    (state) => state.survey?.errorPageLoading
  );
  const showErrorPage = useSelector((state) => state.survey?.showErrorPage);

  const lastYearPageDataResponse = useSelector(
    (state) => state.survey.lastYearPageDataResponse
  );
  const updateStatuses = useSelector((state) => state.survey.updateStatuses);

  // const firstPageId = useSelector((state) => state.allPages?.data[0]?.id);
  // const firstSectionId = useSelector((state) => state.sections?.data[0]?.id);

  const [isLoading, setIsLoading] = useState(true);
  const [prevSelectedPageId, setPrevSelectedPageId] = useState(null);
  const [nextFilingId, setNextFilingId] = useState(null);
  const [prevFilingId, setPrevFilingId] = useState(null);
  const [showPageFilHistory, setShowPageFilHistory] = useState(false);

  const topRef = useRef(null);

  useEffect(() => {
    const fetchPageFilingHistory = async () => {
      try {
        const response = await showPageFilingHistoryLink(selectedPageId);
        setShowPageFilHistory(response.data === true);
      } catch (error) {
        console.error("Error fetching page filing history:", error);
      }
    };

    fetchPageFilingHistory();
  }, [selectedPageId]);

  useEffect(() => {
    // console.log(selectedPageId, prevSelectedPageId);
    if (selectedPageId !== prevSelectedPageId) {
      if (topRef.current) {
        topRef.current.scrollIntoView({ behavior: "smooth" });
      }
      setPrevSelectedPageId(selectedPageId);
    }
  }, [selectedPageId, prevSelectedPageId]);

  useEffect(() => {
    if (errorPageLoading) {
      setIsLoading(true);
    } else if ((!questionsLoading && SectionData) || errorData) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [questionsLoading, errorPageLoading, SectionData]);

  const handleSubmit = () => {};

  useEffect(() => {
    if (nextFilingId) {
      saveAndContinue(nextFilingId);
    }
  }, [nextFilingId]);

  useEffect(() => {
    if (prevFilingId) {
      handleBack(prevFilingId);
    }
  }, [prevFilingId]);

  let pageFilings = useSelector((state) => state.survey.pageFilings);
  const supPageFilings = useSelector((state) => state?.survey?.supPageFilings);

  if (supPageFilings && supPageFilings.length > 0) {
    pageFilings = [...pageFilings, ...supPageFilings];
  }

  const getPageFilingId = (pageId, dir) => {
    const currentPageIndex = pageFilings.findIndex(
      (obj) => obj.pageId === pageId
    );

    if (dir === "next") {
      const nextPageIndex = currentPageIndex + 1;
      if (nextPageIndex < pageFilings.length) {
        return pageFilings[nextPageIndex].id;
      } else {
        return null;
      }
    } else if (dir === "prev") {
      const previousPageIndex = currentPageIndex - 1;
      if (previousPageIndex >= 0) {
        return pageFilings[previousPageIndex].id;
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const handleBack = (prevFilingId) => {
    dispatch(
      fetchPreviousPage({
        pageFilingId: prevFilingId ? prevFilingId : pagePayload?.pageFilingId,
        surveyRespondentId: surveyRespondentId,
      })
    ).then((data) => {
      // call display page api and set the sidebar
      if (data?.payload?.pageId) {
        const sectionId = data?.payload?.sectionFiling?.sectionId;
        const pageId = data?.payload?.pageId;
        dispatch(
          setDataIds({
            secId: sectionId,
            pgId: pageId,
          })
        );
        let payload = { ...pagePayload };
        payload.pageId = data?.payload?.pageId;
        payload.pageFilingId = data?.payload?.id;
        dispatch(fetchQuestionsInPage(payload)).then((data) => {
          if (
            data?.payload?.questionGroupDTOs?.length === 0 &&
            getPageFilingId(pagePayload.pageId, "prev")
          ) {
            setPrevFilingId(getPageFilingId(pagePayload.pageId, "prev"));
          }
        });
      } else if (data === null || (data && !data.payload)) {
        dispatch(setShowLanding(true));
      }
    });
  };

  const saveAndContinue = (nextFilingId) => {
    dispatch(
      fetchNextPage({
        pageFilingId: nextFilingId ? nextFilingId : pagePayload?.pageFilingId,
        surveyRespondentId: surveyRespondentId,
      })
    ).then((data) => {
      // call display page api and set the sidebar
      if (data?.payload?.pageId) {
        const sectionId = data?.payload?.sectionFiling?.sectionId;
        const pageId = data?.payload?.pageId;
        dispatch(
          setDataIds({
            secId: sectionId,
            pgId: pageId,
          })
        );
        let payload = { ...pagePayload };
        payload.pageId = data?.payload?.pageId;
        payload.pageFilingId = data?.payload?.id;
        dispatch(fetchQuestionsInPage(payload)).then((data) => {
          if (
            data?.payload?.questionGroupDTOs?.length === 0 &&
            getPageFilingId(pagePayload.pageId, "next")
          ) {
            setNextFilingId(getPageFilingId(pagePayload.pageId, "next"));
          }
        });
      } else if (data === null || (data && !data.payload)) {
        dispatch(setShowLanding(true));
      }
    });
  };

  const saveAndValidate = () => {
    let { loopIndex, surveyId, ...payload } = pagePayload;
    if (pagePayload) {
      payload = {
        ...payload,
        sectionId: selectedSectionId,
        sectionFilingId: selectedSectionFilingId,
        runAllSurveyValidations: false,
        runAllSectionValidations: false,
        runAllPageValidations: false,
        updateStatuses,
      };
    }

    if (supPages.some((page) => page.id === payload.pageId)) {
      payload.surveyFilingId = supFilingId;
    }

    dispatch(validateSurvey(payload)).then((data) => {
      if (data?.payload?.errors) {
        dispatch(setShowErrorPage(true));
      } else {
        saveAndContinue();
      }
    });
    // dispatch(getSurveyFiling(pagePayload.surveyFilingId));
    // if (surveyFilingId && surveyRespondentId) {
    //   const payload = {
    //     surveyFilingId: surveyFilingId,
    //     surveyRespondentId: surveyRespondentId,
    //   };
    //   dispatch(fetchSectionsBySurveyIdAndRespondenetId(payload));
    // }
  };

  const populatePageFilingHistory = () => {
    let { pageId, surveyFilingId, surveyRespondentId } = pagePayload;
    let newPayload = new Object();
    newPayload.pageId = pageId;
    newPayload.surveyFilingId = surveyFilingId;
    newPayload.surveyRespondentId = surveyRespondentId;
    newPayload.sectionId = selectedSectionId;
    dispatch(fetchPageDataOfLastYear(newPayload)).then((data) => {
      if (data?.payload?.result == "SUCCESS") {
        let payload = { ...pagePayload };
        dispatch(fetchQuestionsInPage(payload));
      }
    });
  };

  return isLoading ? (
    <>
      <Box
        display="flex"
        sx={{ height: "100vh", width: "100%" }}
        bgcolor="#d9e0ed"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress />
      </Box>
    </>
  ) : showErrorPage && errorData ? (
    <>
      <ErrorListPage />
    </>
  ) : (
    <>
      <div className="container" ref={topRef}>
        <div className="box box1">
          <div className="title">
            <Typography
              sx={{ fontSize: 25, fontWeight: "bold" }}
              color="primary"
            >
              {htmlParser(SectionData?.section?.name)}
            </Typography>
            {SectionData?.section?.helpText && (
              <HelpTextPopper question={SectionData?.section}></HelpTextPopper>
            )}
          </div>
          <Typography>{SectionData?.section?.description}</Typography>
          <Divider sx={{ backgroundColor: "black", width: "40%" }} />

          <div className="title">
            <Typography
              sx={{ fontSize: 20, fontWeight: "bold" }}
              color="primary"
            >
              {htmlParser(PageData?.name)}
            </Typography>
            {PageData?.helpText && (
              <HelpTextPopper question={PageData}></HelpTextPopper>
            )}
          </div>
          <Typography style={{ fontWeight: "bold" }}>
            {PageData?.description}
          </Typography>
        </div>
        <div className="box box2">
          <div className="inner-box inner-box1">
            <ProgressBar value={surveyCompletedPercentage} />
            <Typography sx={{ fontWeight: "bold" }}>
              Last Auto-Saved:{" "}
              {lastUpdatedDate
                ? dayjs
                    .utc(lastUpdatedDate)
                    .local()
                    .format("MM-DD-YYYY hh:mm A")
                : ""}
            </Typography>
          </div>
          <div className="inner-box inner-box2">
            <Button
              variant="contained"
              color={"primary"}
              sx={{ mr: 1 }}
              onClick={() => handleBack()}
            >
              Back
            </Button>
            <Button
              variant="contained"
              color={"primary"}
              sx={{ mr: 1 }}
              onClick={() => saveAndContinue()}
            >
              Save & Continue
            </Button>
            <Button
              variant="contained"
              color={"primary"}
              sx={{ mr: 1 }}
              onClick={() => saveAndValidate()}
            >
              Save & Validate
            </Button>
          </div>
        </div>
      </div>
      <div className="divider">
        <Divider sx={{ backgroundColor: "black" }} />
        {showPageFilHistory && (
          <Typography sx={{ fontWeight: "bold", paddingTop: "15px" }}>
            If you choose to, you can{" "}
            <Button
              variant="text"
              size="small"
              sx={{
                fontWeight: "bold",
                backgroundColor: "rgba(128,0,0, 0.15)",
                color: "rgba(128,0,0, 1)",
                textTransform: "lowercase",
                textDecoration: "underline",
                fontSize: "16px",
                "&:hover": {
                  backgroundColor: "rgba(128,0,0, 0.25)",
                  textDecoration: "underline",
                },
              }}
              onClick={() => populatePageFilingHistory()}
            >
              click here
            </Button>{" "}
            to fill this page with last year's data (red checkboxes represent
            last year's answer)
          </Typography>
        )}
      </div>
      <form onSubmit={handleSubmit}>
        {questionsInPage &&
          questionsInPage.map((qes) => (
            <>
              {qes.groupType.name === "radiogrp" && (
                <RenderRadioGrp
                  questionsGrp={qes}
                  type={disableSurvey ? "preview" : ""}
                />
              )}
              {qes.groupType.name === "checkboxgrp" && (
                <RenderCheckboxGrp
                  questionsGrp={qes}
                  type={disableSurvey ? "preview" : ""}
                />
              )}
              {qes.groupType.name === "commongrp" && (
                <RenderCommonGrp
                  questionsGrp={qes}
                  type={disableSurvey ? "preview" : ""}
                />
              )}
              {qes.groupType.name !== "radiogrp" &&
                qes.groupType.name !== "checkboxgrp" &&
                qes.groupType.name !== "commongrp" &&
                qes.questions &&
                qes.questions.length > 0 &&
                qes.questions.length === 1 &&
                qes.questions.map((question) => {
                  return (
                    <RenderQuestion
                      question={question}
                      multiple={false}
                      type={disableSurvey ? "preview" : ""}
                    />
                  );
                })}
              {qes.groupType.name !== "radiogrp" &&
                qes.groupType.name !== "checkboxgrp" &&
                qes.groupType.name !== "commongrp" &&
                qes.questions?.length > 1 && (
                  <>
                    <StyledEngineProvider injectFirst>
                      <Card className="card">
                        <CardContent className="card-header">
                          <Grid container direction={"column"}>
                            <Grid
                              item
                              alignSelf="start"
                              className="question-container"
                            >
                              <Typography variant="question">
                                {htmlParser(
                                  qes.descriptionPosition !== null ||
                                    qes.descriptionPosition !== "No Title"
                                    ? (qes.label || "") + " " + (qes.name || "")
                                    : (qes.label || "") +
                                        " " +
                                        (qes.description || "")
                                )}
                                {qes?.helpText && (
                                  <HelpTextPopper
                                    question={qes}
                                  ></HelpTextPopper>
                                )}
                              </Typography>
                            </Grid>
                          </Grid>
                          <div>
                            <Typography variant="h6">
                              {htmlParser(
                                qes.descriptionPosition !== null ||
                                  qes.descriptionPosition !== "No Title"
                                  ? qes.description || ""
                                  : ""
                              )}
                            </Typography>
                          </div>
                        </CardContent>
                        <CardContent
                          className="card-content"
                          sx={{ backgroundColor: "#fff !important" }}
                        >
                          {qes.questions.map((question) => {
                            return (
                              <RenderQuestion
                                question={question}
                                questionsInPage={qes}
                                multiple={true}
                                type={disableSurvey ? "preview" : ""}
                              />
                            );
                          })}
                        </CardContent>
                      </Card>
                    </StyledEngineProvider>
                  </>
                )}
            </>
          ))}

        <div style={{ width: "100%", textAlign: "end", paddingRight: "15px" }}>
          <div className="inner-box inner-box2">
            <Button
              sx={{ mr: 1 }}
              variant="contained"
              color={"primary"}
              onClick={() => handleBack()}
            >
              Back
            </Button>
            <Button
              sx={{ mr: 1 }}
              variant="contained"
              color={"primary"}
              onClick={() => saveAndContinue()}
            >
              Save & Continue
            </Button>
            <Button
              sx={{ mr: 1 }}
              variant="contained"
              color={"primary"}
              onClick={() => saveAndValidate()}
            >
              Save & Validate
            </Button>
          </div>
        </div>
      </form>
    </>
  );
};

export default SurveyForm;
