import React, { useState } from "react";

import { Box, Typography, Grid } from "@mui/material";
import { TextField as Input } from "@mui/material";
import TextField from "../../../components/Common/TextField";
import Button from "../../../components/Common/Button";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import logo from "../../../assets/aha-logo.png";
import "./LoginPage.scss";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { getTokenForUser } from "../../Survey/Survey.service";
import { LOCAL_STORAGE_KEYS } from "../../../Utility/constants";

const LoginPage = ({ onLogin }) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    username: Yup.string().required("Username is required"),
    password: Yup.string().required("Password is required"),
  });

  const handleUsernameChange = (event) => {
    setUsername(event?.target?.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event?.target?.value);
  };

  const setAuthToken = async () => {
    const token = await getTokenForUser({
      username: "chaitu",
      password: "zcpl@1234",
    });
    localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN_KEY, token.data);
  };

  const handleLogin = async () => {
    try {
      await validationSchema.validate(
        { username, password },
        { abortEarly: false }
      );
      if (username === "admin" && password === "password") {
        await setAuthToken();
        setError("");
        onLogin();
        navigate("/");
      } else {
        setError("Invalid username or password");
      }
    } catch (validationError) {
      const errors = validationError.inner.reduce((acc, error) => {
        acc[error.path] = error.message;
        return acc;
      }, {});
      setError(errors);
    }
  };

  return (
    <Grid container className="login-page">
      <Grid item className="left">
        <Box className="overlay" />
        <Box component="img" src={logo} alt="Logo" className="logo" />
        <Typography variant="h4" className="heading">
          Interactive, Intelligence and user-friendly application
        </Typography>
        <Typography variant="body2" className="support-text">
          Having troubles? Get Help
        </Typography>
      </Grid>
      <Grid item className="right">
        <Typography variant="body2" className="create-account">
          New user? Create an acount
        </Typography>
        <Box className="login-form">
          <Typography variant="d2" gutterBottom>
            Sign in to AHA Survey
          </Typography>
          <Input
            id="username"
            variant="outlined"
            placeholder="Enter username"
            fullWidth
            margin="normal"
            value={username}
            onChange={handleUsernameChange}
            error={!!error.username}
            helperText={error.username}
          />
          <Input
            id="password"
            type={showPassword ? "text" : "password"}
            variant="outlined"
            placeholder="Enter password"
            fullWidth
            margin="normal"
            value={password}
            onChange={handlePasswordChange}
            error={!!error.password}
            helperText={error.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {error && typeof error === "string" && (
            <Typography variant="body2" color="error" gutterBottom>
              {error}
            </Typography>
          )}
          <Typography variant="body2" gutterBottom>
            Forgot password ?
          </Typography>
          <Button onClick={handleLogin}>Sign In</Button>
        </Box>
        <Typography variant="body2" className="copyright">
          Protected by reCAPTCHA and subject to the{" "}
          <span>AHA Survey System Privacy Policy</span> and{" "}
          <span>Terms of Service.</span>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default LoginPage;
