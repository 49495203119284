import React, { useEffect, useRef, useState } from "react";
import { Box, FormControl, FormControlLabel, FormGroup } from "@mui/material";
import Checkbox from "../Common/Checkbox";
import "./QuestionTypes.scss";

const MultipleChoiceQuestion = (props) => {
  const {
    question,
    fieldConfig,
    onChange,
    value,
    errorIndicator,
    type,
    disabledField,
  } = props;

  const [selectedOptions, setSelectedOptions] = useState([]);

  const [checkOptions, setCheckOptions] = useState([]);

  const [checkOptSave, setCheckOptSave] = useState([]);

  useEffect(() => {
    if (question && question?.fieldValues.length) {
      let options = question?.fieldValues.map((opt) => {
        return {
          label: opt.name,
          value: opt.allowableValue,
          isExclusive: opt.exclusiveAnswer,
          historicalAnswer: question?.historicalAnswer?.value,
        };
      });
      setCheckOptions(options);
    }
  }, [question]);

  const handleChange = (event, option) => {
    if (option.isExclusive) {
      // If the option is exclusive and already selected, deselect it
      if (selectedOptions.some((item) => item.value === option.value)) {
        setSelectedOptions([]);
        setCheckOptSave([]);
      } else {
        // If the option is exclusive and not selected, select it and deselect others
        setSelectedOptions([option]);
        setCheckOptSave([option]);
      }
    } else {
      // For non-exclusive options, handle the checkbox change as before
      if (selectedOptions.some((item) => item.value === option.value)) {
        setSelectedOptions(
          selectedOptions.filter((item) => item.value !== option.value)
        );
        setCheckOptSave(
          selectedOptions.filter((item) => item.value !== option.value)
        );
      } else {
        if (selectedOptions.some((item) => item.isExclusive)) {
          setSelectedOptions([option]);
          setCheckOptSave([option]);
        } else {
          setSelectedOptions([...selectedOptions, option]);
          setCheckOptSave([...selectedOptions, option]);
        }
      }
    }
  };

  const prevCheckOptSave = useRef(checkOptSave);

  useEffect(() => {
    const savedAnswer =
      selectedOptions.length > 0
        ? selectedOptions.map((item) => item?.value).join("|") + "|"
        : "";
    if (checkOptSave !== prevCheckOptSave.current) {
      onChange(savedAnswer);
      prevCheckOptSave.current = checkOptSave;
    }
  }, [checkOptSave]);

  useEffect(() => {
    const resultArray = value
      ?.split("|")
      .filter((item) => item !== null && item !== undefined && item !== "");
    let options = resultArray?.map((opt) => {
      const filteredArray = checkOptions.find((item) => item?.value === opt);
      return filteredArray;
    });
    setSelectedOptions(options);
  }, [value, checkOptions]);

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <FormControl component="fieldset">
          <FormGroup
            sx={{
              display: "flex",
              flexDirection: `${fieldConfig?.optionDirection}`,
            }}
          >
            {checkOptions.map((option) => (
              <FormControlLabel
                key={option.value}
                disabled={type === "preview" || disabledField ? true : false}
                control={
                  <Checkbox
                    onChange={(event) => handleChange(event, option)}
                    historicalVal={option?.historicalAnswer?.includes(
                      option.value
                    )}
                    value={option.value}
                    checked={selectedOptions?.some(
                      (item) => item?.value === option?.value
                    )}
                  />
                }
                label={
                  <>
                    {errorIndicator?.displayIndicator && (
                      <span style={{ color: "maroon" }}>{`<< `}</span>
                    )}
                    {option.label}
                  </>
                }
              />
            ))}
          </FormGroup>
        </FormControl>
      </Box>
    </>
  );
};

export default MultipleChoiceQuestion;
