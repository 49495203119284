import React from "react";
import TextField from "../Common/TextField";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import { StyledEngineProvider } from "@mui/material/styles";
import "./QuestionTypes.scss";

const TextInputQuestion = ({
  height,
  question,
  fieldConfig,
  onChange,
  error,
  disabledField,
  ...otherProps
}) => {
  return (
    <>
      <Grid item>
        <TextField
          question={question}
          disabledField={disabledField}
          type={otherProps.type}
          height={height}
          value={question?.answer?.value}
          maxLength={fieldConfig?.maxLength || 4000}
          size={fieldConfig?.size}
          onChange={onChange}
          readOnlyField={question?.readOnlyField}
          ruleErrorText={
            question?.rule?.errorText ? question?.rule?.errorText : null
          }
          {...otherProps}
        />
        {question?.historicalAnswer?.value && (
          <span style={{ color: "maroon" }}>
            {question?.historicalAnswer?.value}
          </span>
        )}
      </Grid>
    </>
  );
};

export default TextInputQuestion;
