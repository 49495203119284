import React, { useState } from "react";
import { Box } from "@mui/material";
import Dropdown from "../Common/Dropdown";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import { StyledEngineProvider } from "@mui/material/styles";
import "./QuestionTypes.scss";

const DropdownQuestion = (props) => {
  const {
    height,
    question,
    options,
    fieldConfig,
    onChange,
    disabledField,
    ...otherProps
  } = props;
  // const [selectedOptions, setSelectedOptions] = useState([]);
  const historicalValue = question.fieldValues.find(
    (field) => field.allowableValue === question.historicalAnswer?.value
  );

  return (
    <>
      <Grid item>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <Dropdown
            question={question}
            height={height}
            type={otherProps.type}
            size={fieldConfig?.size}
            value={question?.answer?.value}
            options={question.fieldValues || []}
            onChange={onChange}
            disabledField={disabledField}
            {...otherProps}
          />
          <Typography sx={{ color: "maroon", mt: "-10px" }}>
            {historicalValue?.name}
          </Typography>
        </Box>
      </Grid>
    </>
  );
};
export default DropdownQuestion;
