import React, { useEffect, useState } from "react";
import { Grid, styled, TextField } from "@mui/material";

const StyledTextField = styled(TextField)`
  textarea {
    resize: both;
  }
`;

const TextArea = (props) => {
  const {
    question,
    value,
    size,
    row = 5,
    onChange,
    placeholder,
    maxLength,
    disabledField,
    readOnlyField,
    ...otherProps
  } = props;

  const [text, setText] = useState("");
  const [error, setError] = useState(null);

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setText(inputValue);

    if (maxLength && inputValue.length > maxLength) {
      setError(`Maximum value is ${maxLength}.`);
    } else {
      setError(null);
      // onChange(inputValue);
    }
  };

  const handleInputBlur = (event) => {
    onChange(event.target.value);
  };

  useEffect(() => {
    setText(value);
  }, [value, question]);

  return (
    <Grid sx={{ display: "flex" }} direction="row" alignItems="center">
      {question?.hideField ? (
        <></>
      ) : (
        <>
          <StyledTextField
            value={text}
            multiline
            rows={row}
            disabled={
              otherProps?.type === "preview" || disabledField ? true : false
            }
            onChange={handleInputChange}
            onBlur={handleInputBlur}
            placeholder={placeholder || ""}
            error={!!error}
            helperText={error}
            variant="outlined"
            sx={{
              "& ::placeholder": {
                color: "rgba(215, 215, 215, 1)",
              },
              mt: 1,
              width: size ? `${size}px` : "100%",
            }}
            InputProps={readOnlyField ? { readOnly: true } : {}}
            {...otherProps}
          />
          {otherProps.errorIndicator?.displayIndicator && (
            <Grid pl={2} style={{ color: "maroon" }}>{`<< `}</Grid>
          )}
        </>
      )}
    </Grid>
  );
};

export default TextArea;
