import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { BASE_URL } from "../../Utility/constants";
import SurveyForm from "../Survey/SurveryForm";
import {
  checkLoginStatus,
  fetchAllPagesBySurveyId,
  // fetchActiveSurveysByRespondentId,
  fetchCountryCodes,
  fetchRespondentDetailsByUsername,
  getLoginAuthenticated,
  setRespondantDetails,
  setActiveSurvey,
  setLoginTextCurrentView,
  setShowFilingList,
} from "../Survey/Survey.slice";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import LandingPage from "./LandingPage";
import { setBuilderLogin } from "../Survey/Survey.slice";
import FilingsTable from "./FilingsTable";

const Home = () => {
  // const questionsInPage = useSelector(
  //   (state) => state.survey.questionsInPage.questionGroupDTOs
  // );
  const navigate = useNavigate();
  const { state } = useLocation();
  const landingStatus = useSelector((state) => state.survey.showLanding);
  const userData = useSelector((state) => state.survey.loggedInUserData);
  const [showLanding, setShowLanding] = useState(landingStatus);
  const [activeFilings, setActiveFilings] = useState(null);
  const dispatch = useDispatch();
  const [showLoading, setShowLoading] = useState(true);
  const [showSurveyList, setShowSurveyList] = useState(false);
  const [showNoSurvey, setShowNoSurvey] = useState(false);
  const userID = useSelector((state) => state.survey.respondentDetails?.id);
  const isbuilderLogin = useSelector((state) => state?.survey?.isbuilderLogin);
  const loginTextCurrentView = useSelector(
    (state) => state?.survey?.loginTextCurrentView
  );

  // useEffect(() => {
  //   if (questionsInPage?.length > 0) {
  //     setShowLanding(false);
  //   }
  // }, [questionsInPage]);

  useEffect(() => {
    // dispatch(fetchRespondentDetailsByUsername(6731275));
    dispatch(checkLoginStatus()).then((res) => {
      console.log("the response of login api: ", res);
      if (res?.payload) {
        if (state?.fromBuilder === true || isbuilderLogin) {
          dispatch(setBuilderLogin(true));
          setShowLoading(false);
          setShowNoSurvey(false);
          navigate("/");
        } else if (!isbuilderLogin) {
          dispatch(getLoginAuthenticated()).then((response) => {
            const activeSurvey = response?.payload?.survey;
            if (
              activeSurvey &&
              typeof activeSurvey === "object" &&
              "id" in activeSurvey
            ) {
              dispatch(setActiveSurvey([activeSurvey]));
              dispatch(fetchAllPagesBySurveyId(activeSurvey?.id));
            }

            if (response?.payload?.surveyRespondents) {
              setNotificationFilingState(response?.payload?.surveyRespondents);
            }
            if (response?.payload?.surveyFiling) {
              dispatch(
                setRespondantDetails(response?.payload?.surveyRespondent)
              );
              setShowLoading(false);
              setShowNoSurvey(false);
              navigate("/");
            } else if (
              !response?.payload?.surveyFiling &&
              response?.payload?.activeSurveyFilings?.length
            ) {
              // show list
              setActiveFilings(response?.payload);
              if (state?.showFilingList !== false) {
                setShowSurveyList(true);
                dispatch(setShowFilingList(true));
              }
              setShowLoading(false);
              setShowNoSurvey(false);
            } else if (
              !response?.payload?.surveyFiling &&
              !response?.payload?.activeSurveyFilings?.length
            ) {
              // display message
              setShowLoading(false);
              setShowNoSurvey(true);
            }
          });
        }
        // navigate('/');
      } else {
        // handle unauthorised case.
        window.location.href = `${BASE_URL}/taker/ssoAnnualTakerLogin`;
        // window.location.href =  'http://localhost:8080/api/v1/taker/ssoAnnualTakerLogin';
      }
    });
  }, [dispatch]);

  // useEffect(() => {
  //   dispatch(fetchRespondentDetailsByUsername(6731275));
  // }, [dispatch, ]);

  useEffect(() => {
    setShowLanding(landingStatus);
  }, [landingStatus]);

  useEffect(() => {
    dispatch(fetchCountryCodes());
  }, [dispatch]);

  // useEffect(() => {
  //   if (userID) {
  //     dispatch(fetchActiveSurveysByRespondentId(userID)).then((data) => {
  //       setShowLoading(false);
  //     });
  //   }
  // }, [dispatch, userID]);

  const setNotificationFilingState = async (surveyRespondents) => {
    if (loginTextCurrentView.length === 0) {
      let payload = [];
      surveyRespondents?.forEach((respondent) => {
        const arr = {
          id: respondent.id,
          isLoginText: false,
        };
        payload.push(arr);
      });
      dispatch(setLoginTextCurrentView(payload));
    }
  };
  const handleSectionClick = async (filing) => {
    await dispatch(setRespondantDetails(filing?.mainSurveyRespondent));
    setShowSurveyList(false);
    dispatch(setShowFilingList(false));

    const activeSurvey = filing?.surveyFiling?.survey;
    if (
      activeSurvey &&
      typeof activeSurvey === "object" &&
      "id" in activeSurvey
    ) {
      dispatch(setActiveSurvey([activeSurvey]));
      dispatch(fetchAllPagesBySurveyId(activeSurvey?.id));
    }
  };

  return showLoading ? (
    <>
      <Box
        display="flex"
        color="primary"
        sx={{ height: "100vh", width: "100%", fontSize: "24px" }}
        alignItems="center"
        justifyContent="center"
      >
        One moment please...
      </Box>
    </>
  ) : (
    <>
      {showNoSurvey ? (
        <>
          <Box
            display="flex"
            color="primary"
            sx={{ height: "100vh", width: "100%", fontSize: "24px" }}
            alignItems="center"
            justifyContent="center"
          >
            <div>
              The Annual Survey site www.ahasurvey.org is currently closed.
              Thank you for completing the 2023 AHA survey. We will return in
              January for the 2024 Annual survey. If you have any questions
              please email
              <span>
                <a href="mailto:surveysupport@aha.org">surveysupport@aha.org</a>
              </span>{" "}
              or you may also call our hotline 1 800 530-9092. Thank you again
              for your support.
            </div>
          </Box>
        </>
      ) : (
        <>
          {showSurveyList ? (
            <>
              <Typography display="inline" sx={{ color: "maroon" }}>
                Please check the list of hospital(s) before you click on the
                name. If this is not accurate please contact us at survey
                support at{" "}
                <a href="mailto:surveysupport@aha.org">surveysupport@aha.org</a>{" "}
                so that we can update your list. Opening a facility that is not
                meant for you is a violation of your agreement.
              </Typography>
              <FilingsTable
                activeFilings={activeFilings}
                handleSectionClick={handleSectionClick}
              />
            </>
          ) : (
            <>{showLanding ? <LandingPage /> : <SurveyForm />}</>
          )}
        </>
      )}
    </>
  );
};

export default Home;
