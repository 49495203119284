import React, { useEffect, useState } from "react";
import { Box, FormControl, FormControlLabel, RadioGroup } from "@mui/material";
import RadioButton from "../Common/RadioButton";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import { StyledEngineProvider } from "@mui/material/styles";
import "./QuestionTypes.scss";
import { htmlParser } from "../../Utility/constants";

const SingleChoiceQuestion = (props) => {
  const {
    value,
    question,
    fieldConfig,
    onChange,
    qesType,
    errorIndicator,
    type,
    disabledField,
  } = props;

  const [options, setOptions] = useState([]);

  const [selectedOption, setSelectedOption] = useState("");

  let flag = false;
  useEffect(() => {
    if (qesType && qesType === "y/n") {
      setOptions([
        {
          name: "Yes",
          val: "Yes",
        },
        {
          name: "No",
          val: "No",
        },
      ]);
    } else {
      const optionsArray = question.fieldValues.map((fieldval) => ({
        name: fieldval.name,
        val: fieldval.allowableValue,
      }));
      setOptions(optionsArray);
    }
  }, [qesType]);

  const handleRadioChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);
    onChange(value);
  };

  const handleRadioLabelDoubleClick = (option) => {
    if (selectedOption === option) {
      setSelectedOption("");
      onChange("");
    }
  };

  useEffect(() => {
    setSelectedOption(value);
  }, [value, question]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          paddingX: "15px",
        }}
      >
        <FormControl component="fieldset">
          <RadioGroup
            sx={{
              display: "flex",
              flexDirection: question.fieldFlow === "NL" ? "column" : "row",
            }}
            value={selectedOption}
            onChange={handleRadioChange}
          >
            {options?.map((option) => (
              <FormControlLabel
                key={option}
                disabled={type === "preview" || disabledField ? true : false}
                control={
                  <RadioButton
                    disabledField={disabledField}
                    type={type}
                    flag={
                      (flag =
                        question?.historicalAnswer?.value === option.val
                          ? true
                          : false)
                    }
                  />
                }
                value={option.val}
                label={
                  <>
                    {errorIndicator?.displayIndicator && (
                      <span style={{ color: "maroon" }}>{`<< `}</span>
                    )}
                    {flag ? (
                      <span style={{ color: "maroon" }}>
                        {htmlParser(option.name)}
                      </span>
                    ) : (
                      htmlParser(option.name)
                    )}
                  </>
                }
                onDoubleClick={() => handleRadioLabelDoubleClick(option.val)}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Box>
    </>
  );
};

export default SingleChoiceQuestion;
