import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button, Card, Grid, Typography } from "@mui/material";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { commentsReport } from "../../pages/Survey/Survey.service";
import dayjs from "dayjs";
import { getTextFromHTML, htmlParser } from "../../Utility/constants";

const GetComments = ({ handleCloseComments }) => {
  const { state } = useLocation();
  const surveyName = useSelector(
    (state) => state.survey.activeSurveys[0]?.name
  );
  const surveyFilingId = useSelector(
    (state) => state.survey.surveyFiling[0]?.id
  );

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [sorting, setSorting] = useState([]);
  const [surveyComments, setSurveyComments] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const [sortBy, setSortBy] = useState(null);
  const [sortAscending, setSortAscending] = useState(null);
  const initialColumns = {
    dateCreated: true,
    commentingOrg: true,
    createdBy: true,
    comment: true,
    sectionName: true,
    pageName: true,
    question: true,
    errorText: true,
    ruleId: true,
    orgName: true,
  };
  useEffect(() => {
    if (surveyFilingId) {
      fetchCommentsReport();
    }
  }, [surveyFilingId, pagination.pageIndex, pagination.pageSize]);

  const handleClose = async (e) => {
    handleCloseComments();
  };
  useEffect(() => {
    if (sorting[0]) {
      if (sorting[0]?.id === "sectionName") {
        setSortBy(`section_name`);
      } else if (sorting[0]?.id === "dateCreated") {
        setSortBy(`date_created`);
      } else if (sorting[0]?.id === "pageName") {
        setSortBy(`page_name`);
      } else if (sorting[0]?.id === "errorText") {
        setSortBy(`error_text`);
      } else if (sorting[0]?.id === "ruleId") {
        setSortBy(`rule_id`);
      } else if (sorting[0]?.id === "orgName") {
        setSortBy(`org_name`);
      } else if (sorting[0]?.id === "commentingOrg") {
        setSortBy(`commenting_org`);
      } else if (sorting[0]?.id === "createdBy") {
        setSortBy(`comment_created_by`);
      } else {
        setSortBy(`${sorting[0]?.id}`);
      }
      setSortAscending(!sorting[0]?.desc);
    }
  }, [sorting]);

  useEffect(() => {
    if (sortBy !== null && sortAscending !== null) {
      fetchCommentsReport();
    }
  }, [sortBy, sortAscending]);

  const fetchCommentsReport = async () => {
    try {
      const payload = {
        surveyFilingId: surveyFilingId,
        pageNumber: pagination.pageIndex + 1,
        numberPerPage: pagination.pageSize,
        sortBy: sortBy,
        sortAscending: sortAscending,
      };
      const res = await commentsReport(payload);
      if (res?.data) {
        setSurveyComments(res?.data?.comments);
        setRowCount(res?.data?.totalNumber);
      } else {
        setSurveyComments([]);
      }
    } catch (error) {
      console.error("Error fetching comments report:", error);
    }
  };
  const data = useMemo(() => {
    return surveyComments?.map((row) => {
      return {
        dateCreated: dayjs
          .utc(row?.dateCreated)
          .local()
          .format("MM-DD-YYYY hh:mm A"),
        comment: row?.comment,
        commentingOrg: row?.commentingOrg,
        createdBy: row?.createdBy,
        sectionName: getTextFromHTML(row?.sectionName),
        pageName: getTextFromHTML(row?.pageName),
        question: row?.question,
        errorText: row?.errorText,
        ruleId: row?.ruleId,
        orgName: row?.orgName,
        color: row?.color,
      };
    });
  }, [surveyComments]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "dateCreated",
        header: "Date Created",
      },
      {
        accessorKey: "commentingOrg",
        header: "Commenting Org.",
      },
      {
        accessorKey: "createdBy",
        header: "Created By",
      },
      {
        accessorKey: "comment",
        header: "Comment",
        Cell: ({ row }) => (
          <>
            {!!row?.original?.color && (
              <span
                style={{
                  color: row.original.color,
                }}
              >
                {htmlParser(row?.original?.comment)}
              </span>
            )}
          </>
        ),
      },
      {
        accessorKey: "sectionName",
        header: "Section Name",
      },
      {
        accessorKey: "pageName",
        header: "Page Name",
      },
      {
        accessorKey: "question",
        header: "Question",
        Cell: ({ row }) => (
          <>
            {!!row?.original?.question && (
              <span>{htmlParser(row?.original?.question)}</span>
            )}
          </>
        ),
      },
      {
        accessorKey: "errorText",
        header: "Error Text",
        Cell: ({ row }) => (
          <>
            {!!row?.original?.errorText && (
              <span>{htmlParser(row?.original?.errorText)}</span>
            )}
          </>
        ),
      },
      {
        accessorKey: "ruleId",
        header: "Rule Id",
      },
      {
        accessorKey: "orgName",
        header: "Org. Name",
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    manualPagination: true,
    manualSorting: true,
    columns,
    data,
    onPaginationChange: setPagination,
    state: { pagination, sorting },
    onSortingChange: setSorting,
    rowCount,
    initialState: { density: "compact" },
    enableColumnFilters: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableHiding: false,
    muiTableBodyCellProps: {
      sx: {
        fontSize: "1rem",
        opacity: "unset",
      },
    },

    muiTableHeadProps: {
      sx: {
        opacity: "unset",
      },
    },
    defaultColumn: {
      maxSize: 300,
      minSize: 80,
      size: 160, //default size is usually 180
    },
  });
  return (
    <div className="panelModal">
      <div className="panelDiv">
        <Typography variant="label">
          Comments for {getTextFromHTML(surveyName)}
        </Typography>
      </div>
      <div className="panelForm">
        <Typography variant="body1">
          This page will allow you to track any changes made to your survey by
          the survey administrative representatives once your survey has been
          accepted in its entirety. Please feel free to monitor this page once
          you have submitted your survey once you have submitted your survey.
        </Typography>

        <MaterialReactTable table={table} />
        <Box sx={{ flexGrow: 1 }} marginTop={5}>
          <Grid container spacing={3} style={{ textAlign: "right" }}>
            <Grid item xs={6}>
              <Button variant="contained" onClick={() => handleClose()}>
                Close
              </Button>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
};

export default GetComments;
