import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import { Grid } from "@mui/material";

const DatePicker = (props) => {
  const { value, size, onChange, question, disabledField, ...otherProps } =
    props;

  const [dateSelected, setDateSelected] = useState(null);

  const [error, setError] = useState(null);

  const handleChange = (date) => {
    if (date === null) {
      setError(null);
    } else {
      if (!date.isValid()) {
        setError("Invalid Date");
      } else {
        setError(null);
        onChange(date.format("MM/DD/YYYY"));
      }
    }
  };

  useEffect(() => {
    if (value) setDateSelected(value);
  }, [value, question]);

  return (
    <Grid sx={{ display: "flex" }} direction="column">
      <Grid sx={{ display: "flex" }} direction="row" alignItems="center">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={["DatePicker"]}>
            <MuiDatePicker
              onChange={handleChange}
              minDate={question?.minDate ? dayjs(question.minDate) : null}
              maxDate={question?.maxDate ? dayjs(question.maxDate) : null}
              disabled={
                otherProps?.type === "preview" || disabledField ? true : false
              }
              slotProps={{
                textField: {
                  helperText: error,
                },
              }}
              value={dateSelected ? dayjs(dateSelected) : null}
              sx={{
                "& ::placeholder": {
                  color: "rgba(215, 215, 215, 1)",
                },
                mt: 1,
                width: size ? `${size * 8}px` : "100%",
              }}
              {...otherProps}
            />
          </DemoContainer>
        </LocalizationProvider>
        {otherProps.errorIndicator?.displayIndicator && (
          <Grid pl={2} style={{ color: "maroon" }}>{`<< `}</Grid>
        )}
      </Grid>
      <Grid>
        {question?.historicalAnswer?.value && (
          <Grid alignSelf={"start"} style={{ color: "maroon" }}>
            {question?.historicalAnswer?.value}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default DatePicker;
