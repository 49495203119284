import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  StyledEngineProvider,
  Typography,
} from "@mui/material";
import {
  fetchSectionData,
  resetSectionsData,
  setTotalSectionData,
} from "../Survey/Survey.slice";
import RenderRadioGrp from "../../components/QuestionTypes/RenderRadioGrp";
import RenderCheckboxGrp from "../../components/QuestionTypes/RenderCheckboxGrp";
import RenderCommonGrp from "../../components/QuestionTypes/RenderCommonGrp";
import RenderQuestion from "../../components/QuestionTypes/RenderQuestion";
import { getTextFromHTML, htmlParser } from "../../Utility/constants";
import HelpTextPopper from "../../components/Common/Popper";
import { useNavigate } from "react-router-dom";
import { getSurveyDto, submitSurvey } from "./Survey.service";

const SubmitSurvey = () => {
  const [surveySubmitted, setSurveySubmitted] = useState(false);
  const [thankYouMsg, setThankYouMsg] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const surveyId = useSelector((state) => state.survey.activeSurveys[0]?.id);
  const surveyRespondentId = useSelector(
    (state) => state.survey.respondentDetails?.id
  );
  const surveyFilingId = useSelector(
    (state) => state.survey.surveyFiling[0]?.id
  );
  const surveyName = useSelector(
    (state) => state.survey.activeSurveys[0]?.name
  );
  const sectionsPageData = useSelector((state) => state.survey?.sectionsData);
  const [surveyData, setSurveyData] = useState([]);
  const [surveyLoaded, setSurveyLoaded] = useState(false);
  const [sectionsLoading, setSectionsLoading] = useState(false);
  let sectionFilings = useSelector((state) => state.survey?.sectionFilings);
  const supSectionFiling = useSelector(
    (state) => state?.survey?.supSectionFiling
  );

  if (supSectionFiling && supSectionFiling.length > 0) {
    sectionFilings = [...sectionFilings, ...supSectionFiling];
  }

  const supFilingId = useSelector(
    (state) => state?.survey?.supplementalFiling?.id
  );

  const builderOrgId = useSelector((state) => state.survey?.builderOrgId);
  const isOpde = useSelector((state) => state?.survey?.isOpde);

  useEffect(() => {
    setSectionsLoading(true);
    sectionFilings.forEach(async (sec) => {
      let payload = {
        surveyId: surveyId,
        surveyFilingId: supSectionFiling.some(
          (supSecFiling) => supSecFiling?.id === sec?.id
        )
          ? supFilingId
          : surveyFilingId,
        sectionId: sec?.sectionId,
        surveyRespondentId: surveyRespondentId,
      };
      await dispatch(fetchSectionData(payload)).then((data) => {
        dispatch(setTotalSectionData(data?.payload));
        setSectionsLoading(false);
      });
    });

    return () => {
      dispatch(resetSectionsData());
    };
  }, []);

  useEffect(() => {
    if (sectionFilings?.length === sectionsPageData?.length) {
      setSurveyLoaded(true);
    }
    if (sectionsPageData.length > 0) {
      setSurveyData(sectionsPageData);
    }
  }, [sectionsPageData]);

  const handleBack = () => {
    if (isOpde) {
      navigate(`/opde/${surveyFilingId}/${builderOrgId}`);
    } else navigate("/");
  };

  const handleBackToSurvey = () => {
    setSurveySubmitted(false);
  };
  const handleSubmit = async () => {
    try {
      const response = await submitSurvey({
        surveyFilingId: surveyFilingId,
        surveyRespondentId: surveyRespondentId,
      });
      if (response?.status === 200 && response.data === true) {
        const surveyDto = await getSurveyDto(surveyId);
        setThankYouMsg(surveyDto?.data?.thankYouMsg);
      } else if (response?.status === 200 && response?.data?.status === 400) {
        setThankYouMsg(response?.data?.detail);
      } else {
        setThankYouMsg("Survey submission failed.");
      }
      setSurveySubmitted(true);
    } catch (error) {
      console.log("Error submitting survey:", error);
    }
  };

  return (
    <Grid
      sx={{
        "@media print": {
          marginLeft: "10px",
        },
      }}
    >
      {sectionsLoading ? (
        <Box
          display="flex"
          sx={{ height: "100%", width: "100%" }}
          bgcolor="#d9e0ed"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <form ml={2}>
          <div
            style={{
              display: surveySubmitted ? "none" : "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                <Typography
                  sx={{ fontSize: 25, fontWeight: "bold" }}
                  color="primary"
                >
                  {getTextFromHTML(surveyName)}
                </Typography>
                <Divider
                  sx={{
                    backgroundColor: "black",
                  }}
                />
              </div>
              {surveyLoaded && (
                <div
                  style={{
                    paddingRight: "15px",
                  }}
                >
                  <div className="inner-box inner-box2">
                    <Button
                      sx={{ mr: 1 }}
                      variant="contained"
                      color={"primary"}
                      onClick={() => handleBack()}
                    >
                      Back
                    </Button>
                    <Button
                      sx={{ mr: 1 }}
                      variant="contained"
                      color={"primary"}
                      onClick={() => handleSubmit()}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              )}
            </div>

            <Typography>
              Please review your survey. Click the Submit button to submit the
              survey, or the Back button to continue editing your survey. Please
              note that once the survey is submitted, you will no longer be able
              to make any changes.
            </Typography>
          </div>
          {surveyData &&
            surveyData.map((section) => (
              <div
                style={{
                  display: surveySubmitted ? "none" : "block",
                }}
              >
                <div className="title">
                  <Typography
                    sx={{
                      fontSize: 20,
                      fontWeight: "bold",
                      paddingBottom: "5px",
                    }}
                    color="primary"
                  >
                    {section?.name}
                  </Typography>
                </div>
                {Object.values(section?.pages).map((page) => (
                  <>
                    {page?.questionGroups.map((qes) => (
                      <>
                        {qes.groupType.name === "radiogrp" && (
                          <RenderRadioGrp
                            type="preview"
                            questionsGrp={qes}
                            hideComment={true}
                          />
                        )}
                        {qes.groupType.name === "checkboxgrp" && (
                          <RenderCheckboxGrp
                            type="preview"
                            questionsGrp={qes}
                            hideComment={true}
                          />
                        )}
                        {qes.groupType.name === "commongrp" && (
                          <RenderCommonGrp
                            type="preview"
                            questionsGrp={qes}
                            hideComment={true}
                          />
                        )}
                        {qes.groupType.name !== "radiogrp" &&
                          qes.groupType.name !== "checkboxgrp" &&
                          qes.groupType.name !== "commongrp" &&
                          qes.questions &&
                          qes.questions.length > 0 &&
                          qes.questions.length === 1 &&
                          qes.questions.map((question) => {
                            return (
                              <RenderQuestion
                                type="preview"
                                question={question}
                                multiple={false}
                                hideComment={true}
                              />
                            );
                          })}
                        {qes.groupType.name !== "radiogrp" &&
                          qes.groupType.name !== "checkboxgrp" &&
                          qes.groupType.name !== "commongrp" &&
                          qes.questions?.length > 1 && (
                            <>
                              <StyledEngineProvider injectFirst>
                                <Card className="card">
                                  <CardContent className="card-header">
                                    <Grid container direction={"column"}>
                                      <Grid
                                        item
                                        alignSelf="start"
                                        className="question-container"
                                      >
                                        <Typography variant="question">
                                          {htmlParser(
                                            qes.descriptionPosition !== null ||
                                              qes.descriptionPosition !==
                                                "No Title"
                                              ? (qes.label || "") +
                                                  " " +
                                                  (qes.name || "")
                                              : (qes.label || "") +
                                                  " " +
                                                  (qes.description || "")
                                          )}
                                          {qes?.helpText && (
                                            <HelpTextPopper
                                              question={qes}
                                            ></HelpTextPopper>
                                          )}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                    <div>
                                      <Typography variant="h6">
                                        {htmlParser(
                                          qes.descriptionPosition !== null ||
                                            qes.descriptionPosition !==
                                              "No Title"
                                            ? qes.description || ""
                                            : ""
                                        )}
                                      </Typography>
                                    </div>
                                  </CardContent>
                                  <CardContent
                                    className="card-content"
                                    sx={{ backgroundColor: "#fff !important" }}
                                  >
                                    {qes.questions.map((question) => {
                                      return (
                                        <RenderQuestion
                                          type="preview"
                                          question={question}
                                          multiple={true}
                                          hideComment={true}
                                        />
                                      );
                                    })}
                                  </CardContent>
                                </Card>
                              </StyledEngineProvider>
                            </>
                          )}
                      </>
                    ))}
                  </>
                ))}
              </div>
            ))}

          <div
            style={{
              display: surveySubmitted ? "flex" : "none",
              flexDirection: "column",
            }}
          >
            <div style={{ margin: "20px 0", textAlign: "center" }}>
              {htmlParser(thankYouMsg)}
            </div>
            <Button
              sx={{ mr: 1, alignSelf: "flex-end" }}
              variant="contained"
              color={"primary"}
              onClick={() => handleBackToSurvey()}
            >
              Back to survey
            </Button>
          </div>
          {surveyLoaded && (
            <div
              style={{
                width: "100%",
                textAlign: "start",
                paddingRight: "15px",
                paddingTop: "10px",
                display: surveySubmitted ? "none" : "block",
              }}
            >
              <div className="inner-box inner-box2">
                <Button
                  sx={{ mr: 1 }}
                  variant="contained"
                  color={"primary"}
                  onClick={() => handleBack()}
                >
                  Back
                </Button>
                <Button
                  sx={{ mr: 1 }}
                  variant="contained"
                  color={"primary"}
                  onClick={() => handleSubmit()}
                >
                  Submit
                </Button>
              </div>
            </div>
          )}
        </form>
      )}
    </Grid>
  );
};

export default SubmitSurvey;
