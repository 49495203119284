import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../pages/Home/HomePage";
import NumericInputQuestion from "../components/QuestionTypes/NumericInputQuestion";
import ErrorCorrection from "../pages/Error-Correction/ErrorCorrecion";
import Glossary from "../pages/Reports/Glossary";
import PrintSurvey from "../pages/Reports/TotalSurvey";

const NumericFieldConfig = {
  value: "",
  size: 800,
  alignField: "left", //right,left,center
  heading: "Test heading",
  alignHeading: "left", //right,left,above,below
  newLine: true,
  allowDecimal: false,
  precision: 2,
  maxDigits: 8,
  min: 20,
  max: 100,
};

const handleNumericChange = (value) => {
  // console.log(value);
};

const PageRoutes = () => {
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route path="/error-correction/:errorId" element={<ErrorCorrection />} />
      {/* <Route path="/glossary" element={<Glossary />} />
      <Route path="/print-survey" element={<PrintSurvey />} /> */}
    </Routes>
  );
};

export default PageRoutes;