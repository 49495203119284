import React from "react";
import { Radio } from "@mui/material";

const RadioButton = ({
  checked,
  onClick,
  value,
  flag,
  type,
  disabledField,
}) => {
  const color = flag ? "maroon" : "";
  const radioStyle = {
    color: color,
  };
  return (
    <Radio
      checked={checked}
      disabled={type === "preview" || disabledField ? true : false}
      onClick={onClick}
      value={value}
      style={radioStyle}
    />
  );
};

export default RadioButton;
