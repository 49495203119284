import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { getTextFromHTML, htmlParser } from "../../Utility/constants";

import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  Chip,
  Box,
  CircularProgress,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {
  fetchQuestionsInPage,
  setDataIds,
  setShowErrorPage,
  setShowLanding,
  fetchSectionErrors,
  fetchAllErrors,
  fetchHistoricalFiling,
  getSurveyFiling,
  fetchSupplementalFiling,
  fetchSupSectionFiling,
  fetchSupPages,
  fetchSupPageFilings,
  fetchSurveyFiling,
  fetchSectionsBySurveyIdAndRespondenetId,
  setCombinedSectionFilings,
  setLoginTextCurrentView,
} from "../Survey/Survey.slice";
import Button from "../../components/Common/Button";
import { Link, useNavigate } from "react-router-dom";
import ProgressBar from "../../components/Common/ProgressBar";

dayjs.extend(utc);

const LandingPage = () => {
  const [loading, setLoading] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const loginTextCurrentView = useSelector(
    (state) => state?.survey?.loginTextCurrentView
  );
  const dispatch = useDispatch();
  let allPages = useSelector((state) => state.survey.allPages);
  const supPages = useSelector((state) => state?.survey?.supPages);
  const supSecsFiling = useSelector((state) => state?.survey?.supSectionFiling);
  if (supPages && supPages.length > 0) {
    allPages = [...allPages, ...supPages];
  }

  const getPageData = (sectionId) => {
    const pageData = allPages.filter(
      (obj) => obj.sequence === 0 && obj.section.id === sectionId
    );
    return pageData[0]?.id;
  };

  let pageFilings = useSelector((state) => state.survey.pageFilings);
  const supPageFilings = useSelector((state) => state?.survey?.supPageFilings);

  if (supPageFilings && supPageFilings.length > 0) {
    pageFilings = [...pageFilings, ...supPageFilings];
  }

  let sectionFilings = useSelector((state) => state.survey.sectionFilings);
  const surveyCompletedPercentage = useSelector(
    (state) => state.survey.surveyFilingDto?.percentPagesCompleted
  );

  const statusChip = (sectionId) => {
    const sectionData = sectionFilings.find(
      (obj) => obj.sectionId === sectionId
    );
    const dateCreated = sectionData?.dateCreated;
    const dateModified = sectionData?.dateModified;
    let chipStyle = {
      borderRadius: "16px",
      height: "40px",
      fontWeight: "600",
      fontSize: "16px",
      padding: "0 10px",
    };

    if (sectionData?.status?.name === "Completed") {
      chipStyle.backgroundColor = "rgba(0, 135, 85, 0.15)";
      chipStyle.color = "rgba(0, 135, 85, 1)";
    } else if (sectionData?.status?.name === "Not Started") {
      chipStyle.backgroundColor = "rgba(0, 48, 135, 0.15)";
      chipStyle.color = "rgba(0, 48, 135, 1)";
    } else if (sectionData?.status?.name === "Errors") {
      chipStyle.backgroundColor = "rgba(128,0,0, 0.15)";
      chipStyle.color = "rgba(128,0,0, 1)";
    } else {
      chipStyle.backgroundColor = "rgba(255, 125, 52, 0.15)";
      chipStyle.color = "rgba(255, 125, 52)";
    }

    if (sectionData?.status?.name === "Not Started") {
      return <Chip label={`Not Started`} sx={chipStyle} />;
    } else {
      const statusDate =
        dateModified && dateModified !== null
          ? dayjs.utc(dateModified).local().format("MM-DD-YYYY hh:mm A")
          : dayjs.utc(dateCreated).local().format("MM-DD-YYYY hh:mm A");
      return sectionData?.status?.name === "Errors" ? (
        <Link to="#" onClick={() => handleErrorChip(sectionId)}>
          <Chip
            label={
              <span>
                <span style={{ textDecoration: "underline" }}>
                  {sectionData?.status?.name}
                </span>
                , {statusDate}
              </span>
            }
            sx={chipStyle}
          />
        </Link>
      ) : (
        <Chip
          label={`${sectionData?.status?.name}, ${statusDate}`}
          sx={chipStyle}
        />
      );
    }
  };

  const surveyFiling = useSelector((state) => state.survey?.surveyFilingDto);
  const surveyFilingId = useSelector(
    (state) => state.survey.surveyFiling[0]?.id
  );
  const loginText = useSelector(
    (state) => state.survey.surveyFiling[0]?.survey?.loginText
  );

  const supFilingId = useSelector(
    (state) => state.survey.supplementalFiling?.id
  );
  const surveyStatus = useSelector(
    (state) => state.survey.surveyFilingDto?.status?.name
  );
  const supStatus = useSelector(
    (state) => state.survey.supplementalFiling?.status?.name
  );
  const respondentDetails = useSelector(
    (state) => state.survey.respondentDetails
  );
  const ahaId = useSelector(
    (state) => state.survey.respondentDetails?.surveyOrganization?.customId
  );
  const surveyName = useSelector(
    (state) => state.survey.activeSurveys[0]?.name
  );
  const HospitalName = useSelector(
    (state) => state.survey.respondentDetails?.surveyOrganization?.name
  );
  // const sectionFilings = useSelector((state) => state.survey.sectionFilings);
  const surveyId = useSelector((state) => state.survey.activeSurveys[0]?.id);
  const surveyRespondentId = useSelector(
    (state) => state.survey.respondentDetails?.id
  );
  function PageFilingId(pageId) {
    const pageFiling = pageFilings.find((obj) => obj.pageId === pageId);
    return pageFiling?.id;
  }
  const startedDate = useSelector(
    (state) => state.survey.surveyFilingDto?.dateCreated
  );
  const lastUpdatedDate = useSelector(
    (state) => state.survey.surveyFilingDto?.dateModified
  );
  const historicalFilingAvailable = useSelector(
    (state) => state.survey.historicalFiling
  );
  const supSectionFiling = useSelector(
    (state) => state?.survey?.supSectionFiling
  );

  if (supSectionFiling && supSectionFiling.length > 0) {
    const supplementFiling = [...supSectionFiling];
    // supplementFiling[0].section.filingType = "supplement";
    sectionFilings = [...sectionFilings, ...supplementFiling];
    // dispatch(setCombinedSectionFilings(sectionFilings));
  }

  const respondentId = useSelector(
    (state) => state?.survey?.respondentDetails?.id
  );
  useEffect(() => {
    if (loginText && loginTextCurrentView.length > 0) {
      const index = loginTextCurrentView.findIndex(
        (item) => item.id === respondentId
      );
      let newArr = [...loginTextCurrentView];
      if (!newArr[index].isLoginText) {
        setConfirmDialogOpen(true);
        const element = {
          id: newArr[index].id,
          isLoginText: true,
        };
        newArr[index] = element;
        dispatch(setLoginTextCurrentView(newArr));
      }
    }
  }, [loginText, loginTextCurrentView]);
  useEffect(() => {
    if (surveyFilingId) {
      dispatch(getSurveyFiling(surveyFilingId)).then((data) => {
        if (data?.payload) {
          dispatch(fetchHistoricalFiling(data?.payload));
        }
      });
    }
  }, [dispatch, surveyFilingId]);

  useEffect(() => {
    if (surveyFilingId && surveyRespondentId) {
      const payload = {
        surveyFilingId: surveyFilingId,
        surveyRespondentId: respondentId,
      };
      dispatch(fetchSectionsBySurveyIdAndRespondenetId(payload));
    }
  }, [dispatch, surveyFilingId, surveyRespondentId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (surveyFiling) {
          const response = await dispatch(
            fetchSupplementalFiling(surveyFiling)
          );
          if (
            response.meta.requestStatus === "fulfilled" &&
            response.payload &&
            respondentId &&
            supFilingId
          ) {
            const payload = {
              surveyFilingId: supFilingId,
              surveyRespondentId: respondentId,
            };
            dispatch(fetchSupSectionFiling(payload));
            dispatch(fetchSupPages(response?.payload?.survey?.id));
            dispatch(fetchSupPageFilings(response?.payload?.id));
          }
        }
      } catch (error) {
        console.error("Error fetching supplemental filing:", error);
      }
    };

    fetchData();
  }, [dispatch, surveyFiling, respondentId, supFilingId]);

  const handleSectionClick = (sectionId) => {
    const pageId = getPageData(sectionId);

    const payload = {
      surveyId: surveyId,
      surveyFilingId: supPages.some((supPage) => supPage?.id === pageId)
        ? supFilingId
        : surveyFilingId,
      pageId: pageId,
      surveyRespondentId: surveyRespondentId,
      pageFilingId: PageFilingId(pageId),
      loopIndex: 1,
    };

    dispatch(setDataIds({ secId: sectionId, pgId: pageId }));
    dispatch(fetchQuestionsInPage(payload));
    dispatch(setShowLanding(false));
    dispatch(setShowErrorPage(false));
  };
  const handleErrorChip = (sectionId) => {
    setLoading(true);
    const pageId = getPageData(sectionId);

    dispatch(setDataIds({ secId: sectionId, pgId: pageId }));
    dispatch(
      fetchSectionErrors({
        surveyFilingId: supSecsFiling.some(
          (supSec) => supSec?.sectionId === sectionId
        )
          ? supFilingId
          : surveyFilingId,
        sectionId,
      })
    ).then((data) => {
      if (data?.payload) {
        setLoading(false);
        dispatch(setShowLanding(false));
        dispatch(setShowErrorPage(true));
      } else if (data?.payload) {
        setLoading(false);
      }
    });
  };
  const handleErrorLink = () => {
    setLoading(true);
    dispatch(fetchAllErrors({ surveyFilingId, surveyRespondentId })).then(
      (data) => {
        if (data?.payload) {
          setLoading(false);
          dispatch(setShowLanding(false));
          dispatch(setShowErrorPage(true));
        } else if (data?.payload) {
          setLoading(false);
        }
      }
    );
  };

  const navigate = useNavigate();
  const handleSubmitSurvey = () => {
    navigate("/submit-survey");
  };
  return (
    <>
      {loading ? (
        <>
          <Box
            display="flex"
            sx={{ height: "100vh", width: "100%" }}
            bgcolor="#d9e0ed"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress />
          </Box>
        </>
      ) : (
        <div
          style={{
            padding: "30px",
            background: "rgba(251, 251, 251, 1)",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              background: "rgba(251, 251, 251, 1)",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div style={{ marginBottom: "16px" }}>
              <Typography color="primary">
                <b>{`${HospitalName} (${ahaId})`}</b>
                <br />
                Welcome {respondentDetails?.name},
              </Typography>
            </div>
            <div
              style={{
                width: "30%",
                height: "20px",
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>
                % Survey Completed:{" "}
              </Typography>
              <div>
                {surveyCompletedPercentage !== undefined &&
                  surveyCompletedPercentage !== null && (
                    <ProgressBar value={surveyCompletedPercentage} />
                  )}
              </div>
            </div>
          </div>

          <div style={{ marginBottom: "40px" }}>
            <Typography color="primary">
              The status of your {htmlParser(surveyName)} is{" "}
              {surveyStatus?.toLowerCase() === "errors" ? (
                <span
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    color: "maroon",
                  }}
                  onClick={handleErrorLink}
                >
                  {surveyStatus?.toLowerCase()}
                </span>
              ) : (
                surveyStatus?.toLowerCase()
              )}
              . It was started on{" "}
              {dayjs.utc(startedDate).local().format("MM-DD-YYYY hh:mm A")}, and
              last updated on{" "}
              {dayjs.utc(lastUpdatedDate).local().format("MM-DD-YYYY hh:mm A")}{" "}
              by {respondentDetails?.name}.{" "}
              {historicalFilingAvailable ? (
                <Typography display="inline">
                  (Prior year's survey data is available.{" "}
                  <Typography display="inline" sx={{ color: "maroon" }}>
                    Prior year's answers are displayed in red.
                  </Typography>
                  )
                </Typography>
              ) : (
                <Typography
                  display="inline"
                  sx={{ fontWeight: "bold", color: "black" }}
                >
                  (Prior year's survey data is not available.)
                </Typography>
              )}
            </Typography>
          </div>
          <TableContainer
            component={Card}
            sx={{
              marginBottom: "40px",
              borderRadius: "16px",
              boxShadow: "0px 4px 6px 0px rgba(62, 73, 84, 0.04)",
            }}
          >
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ padding: "15px 30px " }}>
                    <Typography style={{ fontWeight: "bold" }}>
                      Section Name
                    </Typography>
                  </TableCell>
                  <TableCell style={{ padding: "15px 30px " }}>
                    <Typography style={{ fontWeight: "bold" }}>
                      Status
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sectionFilings?.map((section, index) => (
                  <React.Fragment key={index}>
                    <TableRow>
                      <TableCell
                        style={{ width: "50%", padding: "15px 30px " }}
                      >
                        <Link
                          to="#"
                          onClick={() =>
                            handleSectionClick(section?.section?.id)
                          }
                          style={{
                            textDecoration: "none",
                            color: "inherit",
                          }}
                          onMouseOver={(e) =>
                            (e.target.style.color = "#003087")
                          }
                          onMouseOut={(e) => (e.target.style.color = "inherit")}
                        >
                          <Typography style={{ fontWeight: "bold" }}>
                            {getTextFromHTML(section?.section?.name)}
                          </Typography>
                        </Link>
                      </TableCell>
                      <TableCell style={{ padding: "15px 30px " }}>
                        {statusChip(section?.section?.id)}
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div style={{ alignSelf: "flex-end" }}>
            {surveyStatus?.toLowerCase() === "completed" &&
              (!supStatus ||
                (supStatus && supStatus?.toLowerCase() === "completed")) &&
              respondentDetails?.canSubmitSurvey && (
                <Button
                  style={{ marginRight: "10px" }}
                  onClick={handleSubmitSurvey}
                >
                  Submit Survey
                </Button>
              )}
            <Button
              type="submit"
              onClick={() => {
                if (sectionFilings && sectionFilings.length > 0) {
                  handleSectionClick(sectionFilings?.[0]?.section?.id);
                }
              }}
            >
              Let's Start
            </Button>
          </div>
          <Dialog
            open={confirmDialogOpen}
            onClose={() => setConfirmDialogOpen(false)}
          >
            <DialogContent>{htmlParser(loginText)}</DialogContent>
            <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
              <Button onClick={() => setConfirmDialogOpen(false)}>
                Continue
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </>
  );
};

export default LandingPage;
