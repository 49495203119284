import React, { useEffect, useState } from "react";
import { FormControl, Grid, MenuItem, Select } from "@mui/material";

const Dropdown = ({
  question,
  height,
  value,
  size,
  options,
  onChange,
  multiselect,
  disabledField,
  ...otherProps
}) => {
  const [selectedValues, setSelectedValues] = useState(multiselect ? [] : "");

  const handleSelectChange = (event) => {
    const newSelectedValues = event.target.value;
    setSelectedValues(newSelectedValues);
    onChange(newSelectedValues);
  };

  useEffect(() => {
    setSelectedValues(value);
  }, [value, question]);

  return (
    <Grid sx={{ display: "flex" }} direction="row" alignItems="center">
      {question?.hideField ?
      (<></>) :
      (
        <>
          <FormControl
            sx={{
              width: "100%",
              minWidth: "100%",
            }}
            disabled={
              otherProps?.type === "preview" || disabledField ? true : false
            }
          >
            <Select
              size={height}
              value={selectedValues}
              onChange={handleSelectChange}
              multiple={multiselect ? true : false}
            >
              {options.map((option) => (
                <MenuItem key={option.allowableValue} value={option.allowableValue}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {otherProps.errorIndicator?.displayIndicator && (
            <Grid pl={2} style={{ color: "maroon" }}>{`<< `}</Grid>
          )}
        </>
      )}
    </Grid>
  );
};

export default Dropdown;
